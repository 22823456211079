import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'

// redux
import * as authenticationModule from '../modules/authentication'
import * as sefModule from '../modules/sef'

import * as authApi from '../apiModules/authentication'
import * as sefApi from '../apiModules/sef'

// utils
import { setLocalStorageValues } from '../../utils/localStorageManager'
import { updateParamFilter } from 'utils/searchFilterHelpers'

function* createAgreementTemplate({ payload }) {
  try {
    yield call(sefApi.createAgreementTemplate, payload)
    yield put(sefModule.createAgreementTemplateSuccess())

    navigate(`/dashboard/agreements`)
  } catch (err) {
    yield put(sefModule.createAgreementTemplateFailure(err.response.data))
  }
}

function* createBudgetCategory({ payload }) {
  try {
    yield call(sefApi.createBudgetCategory, payload)
    yield put(sefModule.createBudgetCategorySuccess())

    navigate(`/dashboard/budget-categories`)
  } catch (err) {
    yield put(sefModule.createBudgetCategoryFailure(err.response.data))
  }
}

function* createOutputArea({ payload }) {
  try {
    yield call(sefApi.createOutputArea, payload)
    yield put(sefModule.createOutputAreaSuccess())

    navigate(`/dashboard/output-areas`)
  } catch (err) {
    yield put(sefModule.createOutputAreaFailure(err.response.data))
  }
}

function* createOutputIndicator({ payload }) {
  try {
    yield call(sefApi.createOutputIndicator, payload)
    yield put(sefModule.createOutputIndicatorSuccess())

    navigate(`/dashboard/output-indicators`)
  } catch (err) {
    yield put(sefModule.createOutputIndicatorFailure(err.response.data))
  }
}

function* createPartner({ payload }) {
  try {
    yield call(sefApi.createPartner, payload)
    yield put(sefModule.createPartnerSuccess())

    navigate(`/dashboard/partners`)
  } catch (err) {
    yield put(sefModule.createPartnerFailure(err.response.data))
  }
}

function* createPartnerDistrict({ payload }) {
  try {
    yield call(sefApi.createPartnerDistrict, payload)
    yield put(sefModule.createPartnerDistrictSuccess())

    navigate(`/dashboard/districts`)
  } catch (err) {
    yield put(sefModule.createPartnerDistrictFailure(err.response.data))
  }
}

function* createPurchaseRequest({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      const isLineItemValue = ['lineItems'].includes(value)

      if (payload[value] && isLineItemValue) {
        formDataVals.append(value, JSON.stringify(payload[value]))
      } else if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const { data } = yield call(sefApi.createPurchaseRequest, formDataVals)
    yield put(sefModule.createPurchaseRequestSuccess({ data }))

    navigate(`/dashboard/purchase-requests`)
  } catch (err) {
    const errorMessage = err.response.data
    yield put(sefModule.createPurchaseRequestFailure({ errorMessage, data: payload }))
  }
}

function* createSupplier({ payload }) {
  try {
    yield call(sefApi.createSupplier, payload)
    yield put(sefModule.createSupplierSuccess())

    navigate(`/dashboard/suppliers`)
  } catch (err) {
    yield put(sefModule.createSupplierFailure(err.response.data))
  }
}

function* createWorksite({ payload }) {
  try {
    yield call(sefApi.createWorksite, payload)
    yield put(sefModule.createWorksiteSuccess())

    navigate(`/dashboard/worksites`)
  } catch (err) {
    yield put(sefModule.createWorksiteFailure(err.response.data))
  }
}

function* createWorkspace({ payload }) {
  try {
    yield call(sefApi.createWorkspace, payload)
    yield put(sefModule.createWorkspaceSuccess())

    navigate(`/dashboard/workspaces`)
  } catch (err) {
    yield put(sefModule.createWorkspaceFailure(err.response.data))
  }
}

function* importParticipants({ payload }) {
  try {
    // const formDataVals = new FormData()

    // Object.keys(payload).map(value => {
    //   if (payload[value]) {
    //     formDataVals.append(value, payload[value])
    //   }
    // })

    const { data } = yield call(sefApi.importParticipants, payload)
    yield put(sefModule.importParticipantsSuccess({ data }))

    navigate(`/dashboard/participants`)
  } catch (err) {
    const errorMessage = err.response.data
    yield put(sefModule.importParticipantsFailure({ errorMessage, data: payload }))
  }
}

function* initiatePayrollRequest({ payload }) {
  try {
    const response = yield call(sefApi.initiatePayrollRequest, payload)
    yield put(sefModule.initiatePayrollRequestSuccess(response))

    navigate(`/dashboard/payroll-requests/${response.uuid}?justRequested=true`)
  } catch (err) {
    yield put(sefModule.initiatePayrollRequestFailure(err.response.data))
  }
}

function* deleteBudgetCategory({ payload }) {
  try {
    const { budgetCategoryId } = payload
    yield call(sefApi.deleteBudgetCategory, { budgetCategoryId })
    yield put(sefModule.deleteBudgetCategorySuccess(payload))

    navigate(`/dashboard/budget-categories`)
  } catch (err) {
    yield put(sefModule.deleteBudgetCategoryFailure())
  }
}

function* deleteOutput({ payload }) {
  try {
    const { outputId } = payload
    yield call(sefApi.deleteOutput, { outputId })
    yield put(sefModule.deleteOutputSuccess(payload))

    navigate(`/dashboard/outputs`)
  } catch (err) {
    yield put(sefModule.deleteOutputFailure())
  }
}

function* deleteOutputArea({ payload }) {
  try {
    const { outputAreaId } = payload
    yield call(sefApi.deleteOutputArea, { outputAreaId })
    yield put(sefModule.deleteOutputAreaSuccess(payload))

    navigate(`/dashboard/output-areas`)
  } catch (err) {
    yield put(sefModule.deleteOutputAreaFailure())
  }
}

function* deleteOutputIndicator({ payload }) {
  try {
    const { outputIndicatorId } = payload
    yield call(sefApi.deleteOutputIndicator, { outputIndicatorId })
    yield put(sefModule.deleteOutputIndicatorSuccess(payload))

    navigate(`/dashboard/output-indicators`)
  } catch (err) {
    yield put(sefModule.deleteOutputIndicatorFailure())
  }
}

function* deletePartnerDistrict({ payload }) {
  try {
    const { partnerDistrictId } = payload
    yield call(sefApi.deletePartnerDistrict, { partnerDistrictId })
    yield put(sefModule.deletePartnerDistrictSuccess(payload))

    navigate(`/dashboard/districts`)
  } catch (err) {
    yield put(sefModule.deletePartnerDistrictFailure())
  }
}

function* deletePurchaseRequest({ payload }) {
  try {
    const { purchaseRequestId } = payload
    yield call(sefApi.deletePurchaseRequest, { purchaseRequestId })
    yield put(sefModule.deletePurchaseRequestSuccess(payload))

    navigate(`/dashboard/purchase-requests`)
  } catch (err) {
    yield put(sefModule.deletePurchaseRequestFailure())
  }
}

function* deleteSupplier({ payload }) {
  try {
    const { supplierId } = payload
    yield call(sefApi.deleteSupplier, { supplierId })
    yield put(sefModule.deleteSupplierSuccess(payload))

    navigate(`/dashboard/suppliers`)
  } catch (err) {
    yield put(sefModule.deleteSupplierFailure())
  }
}

function* deleteWorksite({ payload }) {
  try {
    const { worksiteId } = payload
    yield call(sefApi.deleteWorksite, { worksiteId })
    yield put(sefModule.deleteWorksiteSuccess(payload))

    navigate(`/dashboard/worksites`)
  } catch (err) {
    yield put(sefModule.deleteWorksiteFailure())
  }
}

function* deleteWorkspace({ payload }) {
  try {
    const { workspaceId } = payload
    yield call(sefApi.deleteWorkspace, { workspaceId })
    yield put(sefModule.deleteWorkspaceSuccess(payload))

    navigate(`/dashboard/workspaces`)
  } catch (err) {
    yield put(sefModule.deleteWorkspaceFailure())
  }
}

function* fetchAgreementTemplate({ payload }) {
  try {
    const response = yield call(sefApi.fetchAgreementTemplate, payload)
    yield put(sefModule.fetchAgreementTemplateSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchAgreementTemplateFailure(err))
  }
}

function* fetchAgreementTemplates({ payload }) {
  try {
    const response = yield call(sefApi.fetchAgreementTemplates, payload)
    yield put(sefModule.fetchAgreementTemplatesSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchAgreementTemplatesFailure(err))
  }
}

function* fetchBudgetCategories({ payload }) {
  try {
    const response = yield call(sefApi.fetchBudgetCategories, payload)
    yield put(sefModule.fetchBudgetCategoriesSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchBudgetCategoriesFailure(err))
  }
}

function* fetchBudgetCategory({ payload }) {
  try {
    const response = yield call(sefApi.fetchBudgetCategory, payload)
    yield put(sefModule.fetchBudgetCategorySuccess(response))
  } catch (err) {
    yield put(sefModule.fetchBudgetCategoryFailure(err))
  }
}

function* fetchDistricts({ payload }) {
  try {
    const { employeeInviteToken } = payload
    if (employeeInviteToken) {
      const response = yield call(sefApi.fetchDistrictsForOnboarding, payload)
      yield put(sefModule.fetchDistrictsSuccess(response))
    }

    const response = yield call(sefApi.fetchDistricts, payload)
    yield put(sefModule.fetchDistrictsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchDistrictsFailure(err))
  }
}

function* fetchEmployeeDetails({ payload }) {
  try {
    const response = yield call(sefApi.fetchEmployeeDetails, payload)
    yield put(sefModule.fetchEmployeeDetailsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchEmployeeDetailsFailure(err))
  }
}

function* fetchMunicipalities({ payload }) {
  try {
    const response = yield call(sefApi.fetchMunicipalities, payload)
    yield put(sefModule.fetchMunicipalitiesSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchMunicipalitiesFailure(err))
  }
}

function* fetchOnboardingQuestions({ payload }) {
  try {
    const response = yield call(sefApi.fetchOnboardingQuestions, payload)
    yield put(sefModule.fetchOnboardingQuestionsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchOnboardingQuestionsFailure(err))
  }
}

function* fetchOutputArea({ payload }) {
  try {
    const response = yield call(sefApi.fetchOutputArea, payload)
    yield put(sefModule.fetchOutputAreaSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchOutputAreaFailure(err))
  }
}

function* fetchOutputAreas({ payload }) {
  try {
    const response = yield call(sefApi.fetchOutputAreas, payload)
    yield put(sefModule.fetchOutputAreasSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchOutputAreasFailure(err))
  }
}

function* fetchOutputIndicator({ payload }) {
  try {
    const response = yield call(sefApi.fetchOutputIndicator, payload)
    yield put(sefModule.fetchOutputIndicatorSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchOutputIndicatorFailure(err))
  }
}

function* fetchOutputIndicators({ payload }) {
  try {
    const response = yield call(sefApi.fetchOutputIndicators, payload)
    yield put(sefModule.fetchOutputIndicatorsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchOutputIndicatorsFailure(err))
  }
}

function* fetchOutput({ payload }) {
  try {
    const response = yield call(sefApi.fetchOutput, payload)
    yield put(sefModule.fetchOutputSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchOutputFailure(err))
  }
}

function* fetchOutputs({ payload }) {
  try {
    const response = yield call(sefApi.fetchOutputs, payload)
    yield put(sefModule.fetchOutputsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchOutputsFailure(err))
  }
}

function* fetchOwnProfileDetails({ payload }) {
  try {
    const response = yield call(sefApi.fetchOwnProfileDetails, payload)
    yield put(sefModule.fetchOwnProfileDetailsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchOwnProfileDetailsFailure(err))
  }
}

function* fetchPartnerDistrict({ payload }) {
  try {
    const response = yield call(sefApi.fetchPartnerDistrict, payload)
    yield put(sefModule.fetchPartnerDistrictSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchPartnerDistrictFailure(err))
  }
}

function* fetchPartnerDistricts({ payload }) {
  try {
    const response = yield call(sefApi.fetchPartnerDistricts, payload)
    yield put(sefModule.fetchPartnerDistrictsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchPartnerDistrictsFailure(err))
  }
}

function* fetchPartners({ payload }) {
  try {
    const response = yield call(sefApi.fetchPartners, payload)
    yield put(sefModule.fetchPartnersSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchPartnersFailure(err))
  }
}

function* fetchPayrollHistory({ payload }) {
  try {
    const response = yield call(sefApi.fetchPayrollHistory, payload)
    yield put(sefModule.fetchPayrollHistorySuccess(response))
  } catch (err) {
    yield put(sefModule.fetchPayrollHistoryFailure(err))
  }
}

function* fetchPayrollPaymentRequest({ payload }) {
  try {
    const response = yield call(sefApi.fetchPayrollPaymentRequest, payload)
    yield put(sefModule.fetchPayrollPaymentRequestSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchPayrollPaymentRequestFailure(err))
  }
}

function* fetchPlace({ payload }) {
  try {
    const response = yield call(sefApi.fetchPlace, payload)
    yield put(sefModule.fetchPlaceSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchPlaceFailure(err))
  }
}

function* fetchPlaces({ payload }) {
  try {
    const response = yield call(sefApi.fetchPlaces, payload)
    yield put(sefModule.fetchPlacesSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchPlacesFailure(err))
  }
}

function* fetchPurchaseRequest({ payload }) {
  try {
    const response = yield call(sefApi.fetchPurchaseRequest, payload)
    yield put(sefModule.fetchPurchaseRequestSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchPurchaseRequestFailure(err))
  }
}

function* fetchPurchaseRequests({ payload }) {
  try {
    const response = yield call(sefApi.fetchPurchaseRequests, payload)
    yield put(sefModule.fetchPurchaseRequestsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchPurchaseRequestsFailure(err))
  }
}

function* fetchStats({ payload }) {
  try {
    const response = yield call(sefApi.fetchStats, payload)
    yield put(sefModule.fetchStatsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchStatsFailure(err))
  }
}

function* fetchSupplier({ payload }) {
  try {
    const response = yield call(sefApi.fetchSupplier, payload)
    yield put(sefModule.fetchSupplierSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchSupplierFailure(err))
  }
}

function* fetchSuppliers({ payload }) {
  try {
    const response = yield call(sefApi.fetchSuppliers, payload)
    yield put(sefModule.fetchSuppliersSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchSuppliersFailure(err))
  }
}

function* fetchTimekeepers({ payload }) {
  try {
    const response = yield call(sefApi.fetchTimekeepers, payload)
    yield put(sefModule.fetchTimekeepersSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchTimekeepersFailure(err))
  }
}

function* fetchWards({ payload }) {
  try {
    const response = yield call(sefApi.fetchWards, payload)
    yield put(sefModule.fetchWardsSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchWardsFailure(err))
  }
}

function* fetchWorksite({ payload }) {
  try {
    const response = yield call(sefApi.fetchWorksite, payload)
    yield put(sefModule.fetchWorksiteSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchWorksiteFailure(err))
  }
}

function* fetchWorksites({ payload }) {
  try {
    const response = yield call(sefApi.fetchWorksites, payload)
    yield put(sefModule.fetchWorksitesSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchWorksitesFailure(err))
  }
}

function* fetchWorkspace({ payload }) {
  try {
    const response = yield call(sefApi.fetchWorkspace, payload)
    yield put(sefModule.fetchWorkspaceSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchWorkspaceFailure(err))
  }
}

function* fetchWorkspaces({ payload }) {
  try {
    const response = yield call(sefApi.fetchWorkspaces, payload)
    yield put(sefModule.fetchWorkspacesSuccess(response))
  } catch (err) {
    yield put(sefModule.fetchWorkspacesFailure(err))
  }
}

function* requestAgreementZip({ payload }) {
  yield call(sefApi.requestAgreementZip, payload)
}

function* sendOutcomesTrackingReminder({ payload }) {
  try {
    yield call(sefApi.sendOutcomesTrackingReminder, payload)
    yield put(sefModule.sendOutcomesTrackingReminderSuccess())
  } catch (err) {
    yield put(sefModule.sendOutcomesTrackingReminderFailure(err.response.data))
  }
}

function* submitIndicatorOutcomes({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      formDataVals.append(value, payload[value])
    })

    yield call(sefApi.submitIndicatorOutcomes, formDataVals)
    yield put(sefModule.submitIndicatorOutcomesSuccess(payload))

    updateParamFilter({ value: null, valType: 'indicatorId' })
    updateParamFilter({ value: null, valType: 'trackingDate' })
  } catch (err) {
    yield put(sefModule.submitIndicatorOutcomesFailure())
  }
}

function* updateAgreementTemplate({ payload }) {
  try {
    const updatedAgreement = yield call(sefApi.updateAgreementTemplate, payload)
    yield put(sefModule.updateAgreementTemplateSuccess(updatedAgreement))

    navigate(`/dashboard/agreements`)
  } catch (err) {
    yield put(sefModule.updateAgreementTemplateFailure())
  }
}

function* updateBudgetCategory({ payload }) {
  try {
    const updatedOutputArea = yield call(sefApi.updateBudgetCategory, payload)
    yield put(sefModule.updateBudgetCategorySuccess(updatedOutputArea))

    navigate(`/dashboard/budget-categories`)
  } catch (err) {
    yield put(sefModule.updateBudgetCategoryFailure())
  }
}

function* updateOutput({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      formDataVals.append(value, payload[value])
    })

    const { data } = yield call(sefApi.updateOutput, formDataVals)
    yield put(sefModule.updateOutputSuccess({ data }))

    // reload data
    const response = yield call(sefApi.fetchOutputs, payload)
    yield put(sefModule.fetchOutputsSuccess(response))

    navigate(`/dashboard/outputs`)
  } catch (err) {
    yield put(sefModule.updateOutputFailure())
  }
}

function* updateOutputArea({ payload }) {
  try {
    const updatedOutputArea = yield call(sefApi.updateOutputArea, payload)
    yield put(sefModule.updateOutputAreaSuccess(updatedOutputArea))

    navigate(`/dashboard/output-areas`)
  } catch (err) {
    yield put(sefModule.updateOutputAreaFailure())
  }
}

function* updateOutputIndicator({ payload }) {
  try {
    const updatedOutputIndicator = yield call(sefApi.updateOutputIndicator, payload)
    yield put(sefModule.updateOutputIndicatorSuccess(updatedOutputIndicator))

    navigate(`/dashboard/output-indicators`)
  } catch (err) {
    yield put(sefModule.updateOutputIndicatorFailure())
  }
}

function* updatePartnerDistrict({ payload }) {
  try {
    const updatedDistrict = yield call(sefApi.updatePartnerDistrict, payload)
    yield put(sefModule.updatePartnerDistrictSuccess(updatedDistrict))

    navigate(`/dashboard/districts`)
  } catch (err) {
    yield put(sefModule.updatePartnerDistrictFailure())
  }
}

function* updatePayrollRequest({ payload }) {
  try {
    const response = yield call(sefApi.updatePayrollRequest, payload)
    yield put(sefModule.updatePayrollRequestSuccess(response))

    navigate(`/dashboard/payroll`)
  } catch (err) {
    yield put(sefModule.updatePayrollRequestFailure())
  }
}

function* updatePlace({ payload }) {
  try {
    const updatedPlace = yield call(sefApi.updatePlace, payload)
    yield put(sefModule.updatePlaceSuccess(updatedPlace))

    navigate(`/dashboard/places/`)
  } catch (err) {
    yield put(sefModule.updatePlaceFailure())
  }
}

function* updatePurchaseRequest({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      const isLineItemValue = ['lineItems'].includes(value)

      if (payload[value] && isLineItemValue) {
        formDataVals.append(value, JSON.stringify(payload[value]))
      } else if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const { data } = yield call(sefApi.updatePurchaseRequest, formDataVals)
    yield put(sefModule.updatePurchaseRequestSuccess({ data }))

    // reload data
    const response = yield call(sefApi.fetchPurchaseRequests, payload)
    yield put(sefModule.fetchPurchaseRequestsSuccess(response))

    navigate(`/dashboard/purchase-requests`)
  } catch (err) {
    yield put(sefModule.updatePurchaseRequestFailure())
  }
}

function* updateSupplier({ payload }) {
  try {
    const updatedSupplier = yield call(sefApi.updateSupplier, payload)
    yield put(sefModule.updateSupplierSuccess(updatedSupplier))

    navigate(`/dashboard/suppliers/`)
  } catch (err) {
    yield put(sefModule.updateSupplierFailure())
  }
}

function* updateWorksite({ payload }) {
  try {
    const updatedWorksite = yield call(sefApi.updateWorksite, payload)
    yield put(sefModule.updateWorksiteSuccess(updatedWorksite))

    navigate(`/dashboard/worksites/`)
  } catch (err) {
    yield put(sefModule.updateWorksiteFailure())
  }
}

function* updateWorkspace({ payload }) {
  try {
    const updatedWorkspace = yield call(sefApi.updateWorkspace, payload)
    yield put(sefModule.updateWorkspaceSuccess(updatedWorkspace))

    navigate(`/dashboard/workspaces/`)
  } catch (err) {
    yield put(sefModule.updateWorkspaceFailure())
  }
}

function* onboardEmployee({ payload }) {
  try {
    const response = yield call(sefApi.onboardEmployee, payload)
    yield put(sefModule.onboardEmployeeSuccess(response))

    yield setLocalStorageValues(response)
    yield put(authenticationModule.loginSuccess(response.data))

    const authResponse = yield call(authApi.fetchMyRoles)
    yield put(authenticationModule.fetchMyRolesSuccess(authResponse))

    return navigate('dashboard')
  } catch (err) {
    yield put(sefModule.onboardEmployeeFailure())
  }
}

export const sefSagas = [
  takeLatest(sefModule.CREATE_AGREEMENT_TEMPLATE, createAgreementTemplate),
  takeLatest(sefModule.CREATE_BUDGET_CATEGORY, createBudgetCategory),
  takeLatest(sefModule.CREATE_OUTPUT_AREA, createOutputArea),
  takeLatest(sefModule.CREATE_OUTPUT_INDICATOR, createOutputIndicator),
  takeLatest(sefModule.CREATE_PARTNER, createPartner),
  takeLatest(sefModule.CREATE_PARTNER_DISTRICT, createPartnerDistrict),
  takeLatest(sefModule.CREATE_PURCHASE_REQUEST, createPurchaseRequest),
  takeLatest(sefModule.CREATE_SUPPLIER, createSupplier),
  takeLatest(sefModule.CREATE_WORKSITE, createWorksite),
  takeLatest(sefModule.CREATE_WORKSPACE, createWorkspace),
  takeLatest(sefModule.DELETE_BUDGET_CATEGORY, deleteBudgetCategory),
  takeLatest(sefModule.DELETE_OUTPUT, deleteOutput),
  takeLatest(sefModule.DELETE_OUTPUT_AREA, deleteOutputArea),
  takeLatest(sefModule.DELETE_OUTPUT_INDICATOR, deleteOutputIndicator),
  takeLatest(sefModule.DELETE_PARTNER_DISTRICT, deletePartnerDistrict),
  takeLatest(sefModule.DELETE_PURCHASE_REQUEST, deletePurchaseRequest),
  takeLatest(sefModule.DELETE_SUPPLIER, deleteSupplier),
  takeLatest(sefModule.DELETE_WORKSITE, deleteWorksite),
  takeLatest(sefModule.DELETE_WORKSPACE, deleteWorkspace),
  takeLatest(sefModule.FETCH_AGREEMENT_TEMPLATE, fetchAgreementTemplate),
  takeLatest(sefModule.FETCH_AGREEMENT_TEMPLATES, fetchAgreementTemplates),
  takeLatest(sefModule.FETCH_BUDGET_CATEGORY, fetchBudgetCategory),
  takeLatest(sefModule.FETCH_BUDGET_CATEGORIES, fetchBudgetCategories),
  takeLatest(sefModule.FETCH_DISTRICTS, fetchDistricts),
  takeLatest(sefModule.FETCH_EMPLOYEE_DETAILS, fetchEmployeeDetails),
  takeLatest(sefModule.FETCH_MUNICIPALITIES, fetchMunicipalities),
  takeLatest(sefModule.FETCH_ONBOARDING_QUESTIONS, fetchOnboardingQuestions),
  takeLatest(sefModule.FETCH_OUTPUT_AREAS, fetchOutputAreas),
  takeLatest(sefModule.FETCH_OUTPUT_AREA, fetchOutputArea),
  takeLatest(sefModule.FETCH_OUTPUT_INDICATORS, fetchOutputIndicators),
  takeLatest(sefModule.FETCH_OUTPUT_INDICATOR, fetchOutputIndicator),
  takeLatest(sefModule.FETCH_OUTPUT, fetchOutput),
  takeLatest(sefModule.FETCH_OUTPUTS, fetchOutputs),
  takeLatest(sefModule.FETCH_OWN_PROFILE_DETAILS, fetchOwnProfileDetails),
  takeLatest(sefModule.FETCH_PARTNER_DISTRICT, fetchPartnerDistrict),
  takeLatest(sefModule.FETCH_PARTNER_DISTRICTS, fetchPartnerDistricts),
  takeLatest(sefModule.FETCH_PARTNERS, fetchPartners),
  takeLatest(sefModule.FETCH_PAYROLL_HISTORY, fetchPayrollHistory),
  takeLatest(sefModule.FETCH_PAYROLL_PAYMENT_REQUEST, fetchPayrollPaymentRequest),
  takeLatest(sefModule.FETCH_PLACE, fetchPlace),
  takeLatest(sefModule.FETCH_PLACES, fetchPlaces),
  takeLatest(sefModule.FETCH_PURCHASE_REQUEST, fetchPurchaseRequest),
  takeLatest(sefModule.FETCH_PURCHASE_REQUESTS, fetchPurchaseRequests),
  takeLatest(sefModule.FETCH_STATS, fetchStats),
  takeLatest(sefModule.FETCH_SUPPLIER, fetchSupplier),
  takeLatest(sefModule.FETCH_SUPPLIERS, fetchSuppliers),
  takeLatest(sefModule.FETCH_TIMEKEEPERS, fetchTimekeepers),
  takeLatest(sefModule.FETCH_WARDS, fetchWards),
  takeLatest(sefModule.FETCH_WORKSITE, fetchWorksite),
  takeLatest(sefModule.FETCH_WORKSITES, fetchWorksites),
  takeLatest(sefModule.FETCH_WORKSPACE, fetchWorkspace),
  takeLatest(sefModule.FETCH_WORKSPACES, fetchWorkspaces),
  takeLatest(sefModule.IMPORT_PARTICIPANTS, importParticipants),
  takeLatest(sefModule.INITIATE_PAYROLL_REQUEST, initiatePayrollRequest),
  takeLatest(sefModule.REQUEST_AGREEMENT_ZIP, requestAgreementZip),
  takeLatest(sefModule.SEND_OUTCOMES_TRACKING_REMINDER, sendOutcomesTrackingReminder),
  takeLatest(sefModule.SUBMIT_INDICATOR_OUTCOMES, submitIndicatorOutcomes),
  takeLatest(sefModule.UPDATE_AGREEMENT_TEMPLATE, updateAgreementTemplate),
  takeLatest(sefModule.UPDATE_BUDGET_CATEGORY, updateBudgetCategory),
  takeLatest(sefModule.UPDATE_OUTPUT, updateOutput),
  takeLatest(sefModule.UPDATE_OUTPUT_AREA, updateOutputArea),
  takeLatest(sefModule.UPDATE_OUTPUT_INDICATOR, updateOutputIndicator),
  takeLatest(sefModule.UPDATE_PARTNER_DISTRICT, updatePartnerDistrict),
  takeLatest(sefModule.UPDATE_PLACE, updatePlace),
  takeLatest(sefModule.UPDATE_PAYROLL_REQUEST, updatePayrollRequest),
  takeLatest(sefModule.UPDATE_PURCHASE_REQUEST, updatePurchaseRequest),
  takeLatest(sefModule.UPDATE_SUPPLIER, updateSupplier),
  takeLatest(sefModule.UPDATE_WORKSITE, updateWorksite),
  takeLatest(sefModule.UPDATE_WORKSPACE, updateWorkspace),
  takeLatest(sefModule.ONBOARD_EMPLOYEE, onboardEmployee)
]
