import { all } from 'redux-saga/effects'

import { accountActivitySagas } from './accountActivity'
import { applicationSagas } from './applications'
import { attendanceSagas } from './attendance'
import { authenticationSagas } from './authentication'
import { boostsSagas } from './boosts'
import { campaignSagas } from './campaigns'
import { campaignPartnersSagas } from './campaignPartners'
import { categoriesSagas } from './categories'
import { dgmtSagas } from './dgmt'
import { donationsSagas } from './donations'
import { donationConfirmationsSagas } from './donationConfirmations'
import { donationSubscriptionsSagas } from './donationSubscriptions'
import { emailActivitySagas } from './emailActivity'
import { faqsSagas } from './faqs'
import { feedbackSagas } from './feedback'
import { followingsSagas } from './followings'
import { instagramBioLinksSagas } from './instagramBioLinks'
import { messagingSagas } from './messaging'
import { notificationsSagas } from './notifications'
import { opportunitiesSagas } from './opportunities'
import { organisationsSagas } from './organisations'
import { ozowSagas } from './ozow'
import { paymentsSagas } from './payments'
import { peopleSagas } from './people'
import { personalGoalsSagas } from './personalGoals'
import { placesSagas } from './places'
import { platformEventsSagas } from './platformEvents'
import { platformSubscriptionSagas } from './platformSubscriptions'
import { searchSagas } from './search'
import { sefSagas } from './sef'
import { statsSagas } from './stats'
import { storiesSagas } from './stories'
import { teamSagas } from './teams'
import { teamGroupSagas } from './teamGroups'

export default function* rootSaga() {
  yield all([
    ...accountActivitySagas,
    ...applicationSagas,
    ...attendanceSagas,
    ...authenticationSagas,
    ...boostsSagas,
    ...campaignSagas,
    ...campaignPartnersSagas,
    ...categoriesSagas,
    ...dgmtSagas,
    ...donationsSagas,
    ...donationConfirmationsSagas,
    ...donationSubscriptionsSagas,
    ...emailActivitySagas,
    ...faqsSagas,
    ...feedbackSagas,
    ...followingsSagas,
    ...storiesSagas,
    ...instagramBioLinksSagas,
    ...messagingSagas,
    ...notificationsSagas,
    ...opportunitiesSagas,
    ...organisationsSagas,
    ...paymentsSagas,
    ...peopleSagas,
    ...placesSagas,
    ...platformEventsSagas,
    ...platformSubscriptionSagas,
    ...personalGoalsSagas,
    ...searchSagas,
    ...sefSagas,
    ...statsSagas,
    ...teamSagas,
    ...ozowSagas,
    ...teamGroupSagas
  ])
}
