import { createActions, handleActions } from 'redux-actions'

// helpers
import { loadingError, startLoading } from './_helpers'

// config
import constants from 'config/constants'

// Alphabetically ordered constants
export const ACCEPT_HOST_AGREEMENT = 'authentication/ACCEPT_HOST_AGREEMENT'
export const ACCEPT_HOST_AGREEMENT_FAILURE = 'authentication/ACCEPT_HOST_AGREEMENT_FAILURE'
export const ACCEPT_HOST_AGREEMENT_SUCCESS = 'authentication/ACCEPT_HOST_AGREEMENT_SUCCESS'
export const ATTEMPT_AUTO_LOGIN = 'authentication/ATTEMPT_AUTO_LOGIN'
export const CLEAR_SUCCESS_CONFIRMATION = 'general/CLEAR_SUCCESS_CONFIRMATION'
export const CONFIRM_EMAIL = 'authentication/CONFIRM_EMAIL'
export const CONFIRMATION_FAILURE = 'authentication/CONFIRMATION_FAILURE'
export const DISMISS_ONBOARDING_CHALLENGE = 'authentication/DISMISS_ONBOARDING_CHALLENGE'
export const DISMISS_ONBOARDING_CHALLENGE_FAILURE =
  'authentication/DISMISS_ONBOARDING_CHALLENGE_FAILURE'
export const DISMISS_ONBOARDING_CHALLENGE_SUCCESS =
  'authentication/DISMISS_ONBOARDING_CHALLENGE_SUCCESS'
export const FETCH_MY_ROLES = 'authentication/FETCH_MY_ROLES'
export const FETCH_MY_ROLES_FAILURE = 'authentication/FETCH_MY_ROLES_FAILURE'
export const FETCH_MY_ROLES_SUCCESS = 'authentication/FETCH_MY_ROLES_SUCCESS'
export const FETCH_ONBOARDING_CHALLENGES = 'authentication/FETCH_ONBOARDING_CHALLENGES'
export const FETCH_ONBOARDING_CHALLENGES_FAILURE =
  'authentication/FETCH_ONBOARDING_CHALLENGES_FAILURE'
export const FETCH_ONBOARDING_CHALLENGES_SUCCESS =
  'authentication/FETCH_ONBOARDING_CHALLENGES_SUCCESS'
export const GENERATE_API_KEY = 'authentication/GENERATE_API_KEY'
export const GENERATE_API_KEY_FAILURE = 'authentication/GENERATE_API_KEY_FAILURE'
export const GENERATE_API_KEY_SUCCESS = 'authentication/GENERATE_API_KEY_SUCCESS'
export const LOGIN = 'authentication/LOGIN'
export const LOGIN_FAILURE = 'authentication/LOGIN_FAILURE'
export const LOGIN_SUCCESS = 'authentication/LOGIN_SUCCESS'
export const LOGIN_AS_TIMEKEEPER = 'authentication/LOGIN_AS_TIMEKEEPER'
export const LOGIN_AS_TIMEKEEPER_FAILURE = 'authentication/LOGIN_AS_TIMEKEEPER_FAILURE'
export const LOGIN_AS_TIMEKEEPER_SUCCESS = 'authentication/LOGIN_AS_TIMEKEEPER_SUCCESS'
export const LOGOUT = 'authentication/LOGOUT'
export const LOGOUT_FAILURE = 'authentication/LOGOUT_FAILURE'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'
export const REGISTER = 'authentication/REGISTER'
export const REGISTER_FAILURE = 'authentication/REGISTER_FAILURE'
export const REGISTER_SUCCESS = 'authentication/REGISTER_SUCCESS'
export const REQUEST_CONFIRMATION_EMAIL = 'authentication/REQUEST_CONFIRMATION_EMAIL'
export const REQUEST_CONFIRMATION_EMAIL_FAILURE =
  'authentication/REQUEST_CONFIRMATION_EMAIL_FAILURE'
export const REQUEST_CONFIRMATION_EMAIL_SUCCESS =
  'authentication/REQUEST_CONFIRMATION_EMAIL_SUCCESS'
export const RESET_PASSWORD = 'authentication/RESET_PASSWORD'
export const RESET_PASSWORD_FAILURE = 'authentication/RESET_PASSWORD_FAILURE'
export const SEND_MAGIC_LINK = 'authentication/SEND_MAGIC_LINK'
export const SEND_MAGIC_LINK_FAILURE = 'authentication/SEND_MAGIC_LINK_FAILURE'
export const SEND_MAGIC_LINK_SUCCESS = 'authentication/SEND_MAGIC_LINK_SUCCESS'
export const SEND_RESET_LINK = 'authentication/SEND_RESET_LINK'
export const SEND_RESET_LINK_FAILURE = 'authentication/SEND_RESET_LINK_FAILURE'
export const SEND_RESET_LINK_SUCCESS = 'authentication/SEND_RESET_LINK_SUCCESS'

export const {
  authentication: {
    acceptHostAgreement,
    acceptHostAgreementFailure,
    acceptHostAgreementSuccess,
    attemptAutoLogin,
    clearSuccessConfirmation,
    confirmEmail,
    confirmationFailure,
    dismissOnboardingChallenge,
    dismissOnboardingChallengeFailure,
    dismissOnboardingChallengeSuccess,
    fetchMyRoles,
    fetchMyRolesFailure,
    fetchMyRolesSuccess,
    fetchOnboardingChallenges,
    fetchOnboardingChallengesFailure,
    fetchOnboardingChallengesSuccess,
    generateApiKey,
    generateApiKeyFailure,
    generateApiKeySuccess,
    login,
    loginFailure,
    loginSuccess,
    loginAsTimekeeper,
    loginAsTimekeeperFailure,
    loginAsTimekeeperSuccess,
    logout,
    logoutFailure,
    logoutSuccess,
    register,
    registerFailure,
    registerSuccess,
    requestConfirmationEmail,
    requestConfirmationEmailFailure,
    requestConfirmationEmailSuccess,
    resetPassword,
    resetPasswordFailure,
    sendMagicLink,
    sendMagicLinkFailure,
    sendMagicLinkSuccess,
    sendResetLink,
    sendResetLinkFailure,
    sendResetLinkSuccess
  }
} = createActions({
  [ACCEPT_HOST_AGREEMENT]: undefined,
  [ACCEPT_HOST_AGREEMENT_FAILURE]: undefined,
  [ACCEPT_HOST_AGREEMENT_SUCCESS]: undefined,
  [ATTEMPT_AUTO_LOGIN]: undefined,
  [CLEAR_SUCCESS_CONFIRMATION]: undefined,
  [CONFIRM_EMAIL]: undefined,
  [CONFIRMATION_FAILURE]: undefined,
  [DISMISS_ONBOARDING_CHALLENGE]: undefined,
  [DISMISS_ONBOARDING_CHALLENGE_FAILURE]: undefined,
  [DISMISS_ONBOARDING_CHALLENGE_SUCCESS]: undefined,
  [FETCH_MY_ROLES]: undefined,
  [FETCH_MY_ROLES_FAILURE]: undefined,
  [FETCH_MY_ROLES_SUCCESS]: undefined,
  [FETCH_ONBOARDING_CHALLENGES]: undefined,
  [FETCH_ONBOARDING_CHALLENGES_FAILURE]: undefined,
  [FETCH_ONBOARDING_CHALLENGES_SUCCESS]: undefined,
  [GENERATE_API_KEY]: undefined,
  [GENERATE_API_KEY_FAILURE]: undefined,
  [GENERATE_API_KEY_SUCCESS]: undefined,
  [LOGIN]: undefined,
  [LOGIN_FAILURE]: undefined,
  [LOGIN_SUCCESS]: undefined,
  [LOGIN_AS_TIMEKEEPER]: undefined,
  [LOGIN_AS_TIMEKEEPER_FAILURE]: undefined,
  [LOGIN_AS_TIMEKEEPER_SUCCESS]: undefined,
  [LOGOUT]: undefined,
  [LOGOUT_FAILURE]: undefined,
  [LOGOUT_SUCCESS]: undefined,
  [REGISTER]: undefined,
  [REGISTER_FAILURE]: undefined,
  [REGISTER_SUCCESS]: undefined,
  [REQUEST_CONFIRMATION_EMAIL]: undefined,
  [REQUEST_CONFIRMATION_EMAIL_FAILURE]: undefined,
  [REQUEST_CONFIRMATION_EMAIL_SUCCESS]: undefined,
  [RESET_PASSWORD]: undefined,
  [RESET_PASSWORD_FAILURE]: undefined,
  [SEND_MAGIC_LINK]: undefined,
  [SEND_MAGIC_LINK_FAILURE]: undefined,
  [SEND_MAGIC_LINK_SUCCESS]: undefined,
  [SEND_RESET_LINK]: undefined,
  [SEND_RESET_LINK_FAILURE]: undefined,
  [SEND_RESET_LINK_SUCCESS]: undefined
})

const initialState = {
  cliApiKey: null,
  customFieldValues: [],
  customFields: [],
  errorMessage: '',
  followings: [],
  GodAdminRoleableId: null,
  hasAcceptedHostAgreement: false,
  hasFetchedRoles: false,
  hasGodAdminRole: false,
  hasSuperAdminRole: false,
  isError: null,
  isLoading: false,
  notifications: [],
  opportunityApplications: [],
  orgMemberInvites: [],
  orgMemberRequests: [],
  ozowAccountData: {},
  roleData: [],
  submissionWasSuccess: false,
  userNotifPrefs: [],
  userData: {}
}

export default handleActions(
  {
    [ACCEPT_HOST_AGREEMENT]: startLoading,
    [ACCEPT_HOST_AGREEMENT_FAILURE]: loadingError,
    [ACCEPT_HOST_AGREEMENT_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: false,
      hasAcceptedHostAgreement: true,
      ...payload
    }),
    [ATTEMPT_AUTO_LOGIN]: (state, payload) => ({
      ...state,
      isError: null,
      errorMessage: '',
      ...payload
    }),
    [CLEAR_SUCCESS_CONFIRMATION]: state => ({ ...state, submissionWasSuccess: null }),
    [CONFIRM_EMAIL]: payload => ({ isLoading: true, isError: null, errorMessage: '', ...payload }),
    [CONFIRMATION_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Could not confirm your email address'
    }),
    [DISMISS_ONBOARDING_CHALLENGE]: startLoading,
    [DISMISS_ONBOARDING_CHALLENGE_FAILURE]: loadingError,
    [DISMISS_ONBOARDING_CHALLENGE_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isError: false,
        submissionWasSuccess: null
      }
    },
    [FETCH_MY_ROLES]: startLoading,
    [FETCH_MY_ROLES_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      roleData: [],
      customFields: [],
      followings: [],
      userNotifPrefs: [],
      customFieldValues: [],
      opportunityApplications: [],
      campaignApplications: [],
      userData: {},
      hasAcceptedHostAgreement: false,
      hasGodAdminRole: false,
      hasSuperAdminRole: false,
      GodAdminRoleableId: null,
      hasFetchedRoles: true
    }),
    [FETCH_MY_ROLES_SUCCESS]: (state, { payload }) => {
      const {
        user,
        notifications,
        ozowAccountData,
        orgDetails = [],
        orgMemberRequests = [],
        orgMemberInvites = [],
        userRoles = [],
        workspaceDetails = [],
        customFields = [],
        userNotifPrefs = [],
        followings = [],
        customFieldValues = [],
        opportunityApplications,
        campaignApplications,
        hasAcceptedHostAgreement
      } = payload

      const godAdminRole =
        userRoles.filter(r => r.role_type === constants.ROLES.GOD_MODE)[0] || null
      const superAdminRole =
        userRoles.filter(r => r.role_type === constants.ROLES.SUPERADMIN)[0] || null

      return {
        ...state,
        isLoading: false,
        isError: false,
        submissionWasSuccess: null,
        ozowAccountData: ozowAccountData || {},
        hasAcceptedHostAgreement,
        followings,
        userNotifPrefs,
        customFields,
        customFieldValues,
        notifications,
        orgMemberRequests,
        orgMemberInvites,
        workspaceDetails,
        roleData: userRoles,
        orgDetails,
        userData: user,
        opportunityApplications: opportunityApplications || [],
        campaignApplications: campaignApplications || [],
        hasGodAdminRole: !!godAdminRole,
        hasSuperAdminRole: !!superAdminRole,
        GodAdminRoleableId: godAdminRole ? godAdminRole.roleable_id : null,
        hasFetchedRoles: true
      }
    },
    [FETCH_ONBOARDING_CHALLENGES]: startLoading,
    [FETCH_ONBOARDING_CHALLENGES_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true,
      onboardingChallengesData: [],
      hasVerifiedDgmtOrg: false
    }),
    [FETCH_ONBOARDING_CHALLENGES_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isError: false,
        submissionWasSuccess: null,
        onboardingChallengesData: payload.challenges,
        hasVerifiedDgmtOrg: payload.hasVerifiedDgmtOrg
      }
    },
    [GENERATE_API_KEY]: startLoading,
    [GENERATE_API_KEY_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: null,
      cliApiKey: payload.key
    }),
    [GENERATE_API_KEY_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true,
      cliApiKey: null,
      errorMessage: 'Could not generate API key'
    }),
    [LOGIN]: (state, payload) => ({
      ...state,
      isLoading: true,
      isError: null,
      errorMessage: '',
      ...payload
    }),
    [LOGIN_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: payload || 'Incorrect email or password'
    }),
    [LOGIN_SUCCESS]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isError: false
    }),
    [LOGIN_AS_TIMEKEEPER]: (state, payload) => ({
      ...state,
      isLoading: true,
      isError: null,
      errorMessage: '',
      ...payload
    }),
    [LOGIN_AS_TIMEKEEPER_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: payload || 'Incorrect email or password'
    }),
    [LOGIN_AS_TIMEKEEPER_SUCCESS]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isError: false
    }),
    [LOGOUT]: startLoading,
    [LOGOUT_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: 'Could not log you out'
    }),
    [LOGOUT_SUCCESS]: () => ({
      isLoading: false,
      isError: null,
      errorMessage: '',
      cliApiKey: null,
      roleData: [],
      followings: [],
      userNotifPrefs: [],
      customFields: [],
      customFieldValues: [],
      opportunityApplications: [],
      campaignApplications: [],
      userData: {},
      ozowAccountData: {},
      hasAcceptedHostAgreement: false,
      submissionWasSuccess: false,
      hasGodAdminRole: false,
      hasSuperAdminRole: false,
      GodAdminRoleableId: null
    }),
    [REGISTER]: (state, payload) => ({
      ...state,
      isLoading: true,
      isError: null,
      errorMessage: '',
      ...payload
    }),
    [REGISTER_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Something went wrong'
    }),
    [REGISTER_SUCCESS]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isError: false
    }),
    [REQUEST_CONFIRMATION_EMAIL]: (state, payload) => ({
      ...state,
      isLoading: true,
      isError: null,
      errorMessage: '',
      ...payload
    }),
    [REQUEST_CONFIRMATION_EMAIL_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Incorrect email or password',
      responseMessage: 'Could not'
    }),
    [REQUEST_CONFIRMATION_EMAIL_SUCCESS]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isError: false,
      submissionWasSuccess: true
    }),
    [RESET_PASSWORD]: payload => ({
      isLoading: true,
      isError: null,
      errorMessage: '',
      ...payload
    }),
    [RESET_PASSWORD_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: payload.errorMessage
    }),
    [SEND_RESET_LINK]: (state, payload) => ({
      ...state,
      isLoading: true,
      isError: null,
      errorMessage: '',
      ...payload
    }),
    [SEND_RESET_LINK_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Incorrect email or password',
      responseMessage: 'Could not'
    }),
    [SEND_RESET_LINK_SUCCESS]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isError: false,
      submissionWasSuccess: true
    }),
    [SEND_MAGIC_LINK]: (state, payload) => ({
      ...state,
      isLoading: true,
      isError: null,
      errorMessage: '',
      ...payload
    }),
    [SEND_MAGIC_LINK_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Incorrect email or password',
      responseMessage: 'Could not'
    }),
    [SEND_MAGIC_LINK_SUCCESS]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isError: false,
      submissionWasSuccess: true
    })
  },
  initialState
)
