import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'

// utils
import { setLocalStorageValues } from '../../utils/localStorageManager'

// redux
import * as authenticationModule from '../modules/authentication'
import * as dgmtModule from '../modules/dgmt'
import * as notificationsModule from '../modules/notifications'
import * as organisationsModule from '../modules/organisations'

import * as dgmtApi from '../apiModules/dgmt'
import * as notificationsApi from '../apiModules/notifications'

function* createPboApplication({ payload }) {
  try {
    const isDraft = !!payload.saveDraft
    const draftParam = isDraft ? '?draftSaved=true' : ''
    const redirectPath = `/dashboard${draftParam}`

    const formDataVals = new FormData()
    Object.keys(payload).map(prop => {
      const value = payload[prop]
      if (value) {
        formDataVals.append(prop, value)
      }
    })

    const pboResponse = yield call(dgmtApi.createPboApplication, formDataVals)

    yield put(authenticationModule.loginSuccess(pboResponse.data))
    yield put(organisationsModule.createSuccess(pboResponse.data))
    yield put(dgmtModule.createPboApplicationSuccess({ data: pboResponse.data }))
    yield setLocalStorageValues(pboResponse)

    navigate(redirectPath)
  } catch (err) {
    const errorMessage = err.response.data
    yield put(dgmtModule.createPboApplicationFailure({ errorMessage, data: payload }))
  }
}

function* deletePboApplication({ payload }) {
  try {
    const { pboApplicationId } = payload
    yield call(dgmtApi.deletePboApplication, { pboApplicationId })
    yield put(dgmtModule.deletePboApplicationSuccess(payload))

    navigate(`/dashboard/sir-applications`)
  } catch (err) {
    yield put(dgmtModule.deletePboApplicationFailure())
  }
}

function* fetchMnos({ payload }) {
  try {
    const response = yield call(dgmtApi.fetchMnos, payload)
    yield put(dgmtModule.fetchMnosSuccess(response))
  } catch (err) {
    yield put(dgmtModule.fetchMnosFailure(err))
  }
}

function* fetchDgmtWebsites({ payload }) {
  try {
    const response = yield call(dgmtApi.fetchDgmtWebsites, payload)
    yield put(dgmtModule.fetchDgmtWebsitesSuccess(response))
  } catch (err) {
    yield put(dgmtModule.fetchDgmtWebsitesFailure(err))
  }
}

function* fetchPboApplication({ payload }) {
  try {
    const response = yield call(dgmtApi.fetchPboApplication, payload)
    yield put(dgmtModule.fetchPboApplicationSuccess(response))
  } catch (err) {
    yield put(dgmtModule.fetchPboApplicationFailure(err))
  }
}

function* fetchPboApplications({ payload }) {
  try {
    const response = yield call(dgmtApi.fetchPboApplications, payload)
    yield put(dgmtModule.fetchPboApplicationsSuccess(response))
  } catch (err) {
    yield put(dgmtModule.fetchPboApplicationsFailure(err))
  }
}

function* fetchListingRequest({ payload }) {
  try {
    const response = yield call(dgmtApi.fetchListingRequest, payload)
    yield put(dgmtModule.fetchListingRequestSuccess(response))
  } catch (err) {
    yield put(dgmtModule.fetchListingRequestFailure(err))
  }
}

function* fetchListingRequests({ payload }) {
  try {
    const response = yield call(dgmtApi.fetchListingRequests, payload)
    yield put(dgmtModule.fetchListingRequestsSuccess(response))
  } catch (err) {
    yield put(dgmtModule.fetchListingRequestsFailure(err))
  }
}

function* updatePboApplication({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      const isLineItemValue = ['lineItems'].includes(value)

      if (payload[value] && isLineItemValue) {
        formDataVals.append(value, JSON.stringify(payload[value]))
      } else if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const { data } = yield call(dgmtApi.updatePboApplication, formDataVals)
    yield put(dgmtModule.updatePboApplicationSuccess({ data }))

    // reload data
    const response = yield call(dgmtApi.fetchPboApplications, payload)
    yield put(dgmtModule.fetchPboApplicationsSuccess(response))

    // fetch notifications again because might need dismissing
    const notifsResponse = yield call(notificationsApi.fetchNotifications)
    yield put(notificationsModule.fetchNotificationsSuccess(notifsResponse))

    navigate(`/dashboard/sir-applications`)
  } catch (err) {
    yield put(dgmtModule.updatePboApplicationFailure())
  }
}

function* updateListingRequest({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const { data } = yield call(dgmtApi.updateListingRequest, formDataVals)
    yield put(dgmtModule.updateListingRequestSuccess({ data }))

    // reload data
    const response = yield call(dgmtApi.fetchListingRequests, payload)
    yield put(dgmtModule.fetchListingRequestsSuccess(response))

    navigate(`/dashboard/skipdata-requests`)
  } catch (err) {
    yield put(dgmtModule.updateListingRequestFailure())
  }
}

function* submitAdditionalWebsites({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(prop => {
      const value = payload[prop]
      if (value) {
        formDataVals.append(prop, value)
      }
    })

    const { data } = yield call(dgmtApi.submitAdditionalWebsites, formDataVals)
    yield put(dgmtModule.submitAdditionalWebsitesSuccess({ data }))
    navigate(`/dashboard/sir-applications`)
  } catch (err) {
    const errorMessage = err.response.data
    yield put(dgmtModule.submitAdditionalWebsitesFailure({ errorMessage, data: payload }))
  }
}

export const dgmtSagas = [
  takeLatest(dgmtModule.FETCH_MNOS, fetchMnos),
  takeLatest(dgmtModule.FETCH_DGMT_WEBSITES, fetchDgmtWebsites),
  takeLatest(dgmtModule.CREATE_PBO_APPLICATION, createPboApplication),
  takeLatest(dgmtModule.DELETE_PBO_APPLICATION, deletePboApplication),
  takeLatest(dgmtModule.FETCH_PBO_APPLICATION, fetchPboApplication),
  takeLatest(dgmtModule.FETCH_PBO_APPLICATIONS, fetchPboApplications),
  takeLatest(dgmtModule.UPDATE_PBO_APPLICATION, updatePboApplication),
  takeLatest(dgmtModule.SUBMIT_ADDITIONAL_WEBSITES, submitAdditionalWebsites),
  takeLatest(dgmtModule.FETCH_LISTING_REQUEST, fetchListingRequest),
  takeLatest(dgmtModule.FETCH_LISTING_REQUESTS, fetchListingRequests),
  takeLatest(dgmtModule.UPDATE_LISTING_REQUEST, updateListingRequest)
]
