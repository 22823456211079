import axios from 'axios'

// config
import baseUrl from '../config'

export const fetchParticipantAttendance = async participantId => {
  const response = await axios.get(`${baseUrl}/sef/attendances/participants/${participantId}`, {
    withCredentials: true
  })

  return response.data
}

export const createParticipantAttendance = async data => {
  return await axios({
    withCredentials: true,
    method: 'POST',
    url: `${baseUrl}/sef/attendances`,
    data
  })
}

export const updateParticipantAttendance = async ({ attendanceLogId, data }) => {
  return await axios({
    withCredentials: true,
    method: 'PATCH',
    url: `${baseUrl}/sef/attendances/${attendanceLogId}`,
    data
  })
}
