import axios from 'axios'

// config
import baseUrl from '../config'

export const fetchDgmtWebsites = async params => {
  const response = await axios.get(`${baseUrl}/dgmt/skipdata-requests`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const createPboApplication = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/dgmt/pbo-applications`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const submitAdditionalWebsites = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/dgmt/additional-websites`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const deletePboApplication = async ({ pboApplicationId }) => {
  return await axios.delete(`${baseUrl}/dgmt/pbo-applications/${pboApplicationId}`, {
    withCredentials: true
  })
}

export const fetchPboApplication = async id => {
  const response = await axios.get(`${baseUrl}/dgmt/pbo-applications/${id}`, {
    withCredentials: true
  })
  return response.data
}

export const fetchPboApplications = async params => {
  const response = await axios.get(`${baseUrl}/dgmt/pbo-applications`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const updatePboApplication = async pboApplication => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/dgmt/pbo-applications/${pboApplication.get('id')}`,
    data: pboApplication,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const fetchListingRequests = async params => {
  const response = await axios.get(`${baseUrl}/dgmt/listing-requests`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchListingRequest = async id => {
  const response = await axios.get(`${baseUrl}/dgmt/listing-requests/${id}`, {
    withCredentials: true
  })
  return response.data
}

export const fetchMnos = async params => {
  const response = await axios.get(`${baseUrl}/dgmt/mnos`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const updateListingRequest = async listingRequest => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/dgmt/listing-requests/${listingRequest.get('id')}`,
    data: listingRequest,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}
