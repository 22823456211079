import { createActions, handleActions } from 'redux-actions'

// helpers
import { loadingError, startLoading, startSubmitting } from './_helpers'

// utils
import idsMatch from 'utils/idsMatch'

export const CREATE_AGREEMENT_TEMPLATE = 'sef/CREATE_AGREEMENT_TEMPLATE'
export const CREATE_AGREEMENT_TEMPLATE_FAILURE = 'sef/CREATE_AGREEMENT_TEMPLATE_FAILURE'
export const CREATE_AGREEMENT_TEMPLATE_SUCCESS = 'sef/CREATE_AGREEMENT_TEMPLATE_SUCCESS'
export const CREATE_BUDGET = 'sef/CREATE_BUDGET'
export const CREATE_BUDGET_FAILURE = 'sef/CREATE_BUDGET_FAILURE'
export const CREATE_BUDGET_SUCCESS = 'sef/CREATE_BUDGET_SUCCESS'
export const CREATE_BUDGET_CATEGORY = 'sef/CREATE_BUDGET_CATEGORY'
export const CREATE_BUDGET_CATEGORY_FAILURE = 'sef/CREATE_BUDGET_CATEGORY_FAILURE'
export const CREATE_BUDGET_CATEGORY_SUCCESS = 'sef/CREATE_BUDGET_CATEGORY_SUCCESS'
export const CREATE_OUTPUT_AREA = 'sef/CREATE_OUTPUT_AREA'
export const CREATE_OUTPUT_AREA_FAILURE = 'sef/CREATE_OUTPUT_AREA_FAILURE'
export const CREATE_OUTPUT_AREA_SUCCESS = 'sef/CREATE_OUTPUT_AREA_SUCCESS'
export const CREATE_OUTPUT_INDICATOR = 'sef/CREATE_OUTPUT_INDICATOR'
export const CREATE_OUTPUT_INDICATOR_FAILURE = 'sef/CREATE_OUTPUT_INDICATOR_FAILURE'
export const CREATE_OUTPUT_INDICATOR_SUCCESS = 'sef/CREATE_OUTPUT_INDICATOR_SUCCESS'
export const CREATE_PARTNER = 'sef/CREATE_PARTNER'
export const CREATE_PARTNER_FAILURE = 'sef/CREATE_PARTNER_FAILURE'
export const CREATE_PARTNER_SUCCESS = 'sef/CREATE_PARTNER_SUCCESS'
export const CREATE_PARTNER_DISTRICT = 'sef/CREATE_PARTNER_DISTRICT'
export const CREATE_PARTNER_DISTRICT_FAILURE = 'sef/CREATE_PARTNER_DISTRICT_FAILURE'
export const CREATE_PARTNER_DISTRICT_SUCCESS = 'sef/CREATE_PARTNER_DISTRICT_SUCCESS'
export const CREATE_PURCHASE_REQUEST = 'sef/CREATE_PURCHASE_REQUEST'
export const CREATE_PURCHASE_REQUEST_FAILURE = 'sef/CREATE_PURCHASE_REQUEST_FAILURE'
export const CREATE_PURCHASE_REQUEST_SUCCESS = 'sef/CREATE_PURCHASE_REQUEST_SUCCESS'
export const CREATE_SUPPLIER = 'sef/CREATE_SUPPLIER'
export const CREATE_SUPPLIER_FAILURE = 'sef/CREATE_SUPPLIER_FAILURE'
export const CREATE_SUPPLIER_SUCCESS = 'sef/CREATE_SUPPLIER_SUCCESS'
export const CREATE_WORKSITE = 'sef/CREATE_WORKSITE'
export const CREATE_WORKSITE_FAILURE = 'sef/CREATE_WORKSITE_FAILURE'
export const CREATE_WORKSITE_SUCCESS = 'sef/CREATE_WORKSITE_SUCCESS'
export const CREATE_WORKSPACE = 'sef/CREATE_WORKSPACE'
export const CREATE_WORKSPACE_FAILURE = 'sef/CREATE_WORKSPACE_FAILURE'
export const CREATE_WORKSPACE_SUCCESS = 'sef/CREATE_WORKSPACE_SUCCESS'
export const DELETE_BUDGET_CATEGORY = 'sef/DELETE_BUDGET_CATEGORY'
export const DELETE_BUDGET_CATEGORY_FAILURE = 'sef/DELETE_BUDGET_CATEGORY_FAILURE'
export const DELETE_BUDGET_CATEGORY_SUCCESS = 'sef/DELETE_BUDGET_CATEGORY_SUCCESS'
export const DELETE_OUTPUT = 'sef/DELETE_OUTPUT'
export const DELETE_OUTPUT_FAILURE = 'sef/DELETE_OUTPUT_FAILURE'
export const DELETE_OUTPUT_SUCCESS = 'sef/DELETE_OUTPUT_SUCCESS'
export const DELETE_OUTPUT_AREA = 'sef/DELETE_OUTPUT_AREA'
export const DELETE_OUTPUT_AREA_FAILURE = 'sef/DELETE_OUTPUT_AREA_FAILURE'
export const DELETE_OUTPUT_AREA_SUCCESS = 'sef/DELETE_OUTPUT_AREA_SUCCESS'
export const DELETE_OUTPUT_INDICATOR = 'sef/DELETE_OUTPUT_INDICATOR'
export const DELETE_OUTPUT_INDICATOR_FAILURE = 'sef/DELETE_OUTPUT_INDICATOR_FAILURE'
export const DELETE_OUTPUT_INDICATOR_SUCCESS = 'sef/DELETE_OUTPUT_INDICATOR_SUCCESS'
export const DELETE_PARTNER_DISTRICT = 'sef/DELETE_PARTNER_DISTRICT'
export const DELETE_PARTNER_DISTRICT_FAILURE = 'sef/DELETE_PARTNER_DISTRICT_FAILURE'
export const DELETE_PARTNER_DISTRICT_SUCCESS = 'sef/DELETE_PARTNER_DISTRICT_SUCCESS'
export const DELETE_PURCHASE_REQUEST = 'sef/DELETE_PURCHASE_REQUEST'
export const DELETE_PURCHASE_REQUEST_FAILURE = 'sef/DELETE_PURCHASE_REQUEST_FAILURE'
export const DELETE_PURCHASE_REQUEST_SUCCESS = 'sef/DELETE_PURCHASE_REQUEST_SUCCESS'
export const DELETE_SUPPLIER = 'sef/DELETE_SUPPLIER'
export const DELETE_SUPPLIER_FAILURE = 'sef/DELETE_SUPPLIER_FAILURE'
export const DELETE_SUPPLIER_SUCCESS = 'sef/DELETE_SUPPLIER_SUCCESS'
export const DELETE_WORKSITE = 'sef/DELETE_WORKSITE'
export const DELETE_WORKSITE_FAILURE = 'sef/DELETE_WORKSITE_FAILURE'
export const DELETE_WORKSITE_SUCCESS = 'sef/DELETE_WORKSITE_SUCCESS'
export const DELETE_WORKSPACE = 'sef/DELETE_WORKSPACE'
export const DELETE_WORKSPACE_FAILURE = 'sef/DELETE_WORKSPACE_FAILURE'
export const DELETE_WORKSPACE_SUCCESS = 'sef/DELETE_WORKSPACE_SUCCESS'
export const FETCH_AGREEMENT_TEMPLATE = 'sef/FETCH_AGREEMENT_TEMPLATE'
export const FETCH_AGREEMENT_TEMPLATE_FAILURE = 'sef/FETCH_AGREEMENT_TEMPLATE_FAILURE'
export const FETCH_AGREEMENT_TEMPLATE_SUCCESS = 'sef/FETCH_AGREEMENT_TEMPLATE_SUCCESS'
export const FETCH_AGREEMENT_TEMPLATES = 'sef/FETCH_AGREEMENT_TEMPLATES'
export const FETCH_AGREEMENT_TEMPLATES_FAILURE = 'sef/FETCH_AGREEMENT_TEMPLATES_FAILURE'
export const FETCH_AGREEMENT_TEMPLATES_SUCCESS = 'sef/FETCH_AGREEMENT_TEMPLATES_SUCCESS'
export const FETCH_BUDGET = 'sef/FETCH_BUDGET'
export const FETCH_BUDGET_FAILURE = 'sef/FETCH_BUDGET_FAILURE'
export const FETCH_BUDGET_SUCCESS = 'sef/FETCH_BUDGET_SUCCESS'
export const FETCH_BUDGETS = 'sef/FETCH_BUDGETS'
export const FETCH_BUDGETS_FAILURE = 'sef/FETCH_BUDGETS_FAILURE'
export const FETCH_BUDGETS_SUCCESS = 'sef/FETCH_BUDGETS_SUCCESS'
export const FETCH_BUDGET_CATEGORIES = 'sef/FETCH_BUDGET_CATEGORIES'
export const FETCH_BUDGET_CATEGORIES_FAILURE = 'sef/FETCH_BUDGET_CATEGORIES_FAILURE'
export const FETCH_BUDGET_CATEGORIES_SUCCESS = 'sef/FETCH_BUDGET_CATEGORIES_SUCCESS'
export const FETCH_BUDGET_CATEGORY = 'sef/FETCH_BUDGET_CATEGORY'
export const FETCH_BUDGET_CATEGORY_FAILURE = 'sef/FETCH_BUDGET_CATEGORY_FAILURE'
export const FETCH_BUDGET_CATEGORY_SUCCESS = 'sef/FETCH_BUDGET_CATEGORY_SUCCESS'
export const FETCH_DISTRICTS = 'sef/FETCH_DISTRICTS'
export const FETCH_DISTRICTS_FAILURE = 'sef/FETCH_DISTRICTS_FAILURE'
export const FETCH_DISTRICTS_SUCCESS = 'sef/FETCH_DISTRICTS_SUCCESS'
export const FETCH_EMPLOYEE_DETAILS = 'sef/FETCH_EMPLOYEE_DETAILS'
export const FETCH_EMPLOYEE_DETAILS_FAILURE = 'sef/FETCH_EMPLOYEE_DETAILS_FAILURE'
export const FETCH_EMPLOYEE_DETAILS_SUCCESS = 'sef/FETCH_EMPLOYEE_DETAILS_SUCCESS'
export const FETCH_MUNICIPALITIES = 'sef/FETCH_MUNICIPALITIES'
export const FETCH_MUNICIPALITIES_FAILURE = 'sef/FETCH_MUNICIPALITIES_FAILURE'
export const FETCH_MUNICIPALITIES_SUCCESS = 'sef/FETCH_MUNICIPALITIES_SUCCESS'
export const FETCH_MUNICIPALITY = 'sef/FETCH_MUNICIPALITY'
export const FETCH_MUNICIPALITY_FAILURE = 'sef/FETCH_MUNICIPALITY_FAILURE'
export const FETCH_MUNICIPALITY_SUCCESS = 'sef/FETCH_MUNICIPALITY_SUCCESS'
export const FETCH_ONBOARDING_QUESTIONS = 'sef/FETCH_ONBOARDING_QUESTIONS'
export const FETCH_ONBOARDING_QUESTIONS_FAILURE = 'sef/FETCH_ONBOARDING_QUESTIONS_FAILURE'
export const FETCH_ONBOARDING_QUESTIONS_SUCCESS = 'sef/FETCH_ONBOARDING_QUESTIONS_SUCCESS'
export const FETCH_OUTPUT = 'sef/FETCH_OUTPUT'
export const FETCH_OUTPUT_FAILURE = 'sef/FETCH_OUTPUT_FAILURE'
export const FETCH_OUTPUT_SUCCESS = 'sef/FETCH_OUTPUT_SUCCESS'
export const FETCH_OUTPUT_AREA = 'sef/FETCH_OUTPUT_AREA'
export const FETCH_OUTPUT_AREA_FAILURE = 'sef/FETCH_OUTPUT_AREA_FAILURE'
export const FETCH_OUTPUT_AREA_SUCCESS = 'sef/FETCH_OUTPUT_AREA_SUCCESS'
export const FETCH_OUTPUT_AREAS = 'sef/FETCH_OUTPUT_AREAS'
export const FETCH_OUTPUT_AREAS_FAILURE = 'sef/FETCH_OUTPUT_AREAS_FAILURE'
export const FETCH_OUTPUT_AREAS_SUCCESS = 'sef/FETCH_OUTPUT_AREAS_SUCCESS'
export const FETCH_OUTPUT_INDICATOR = 'sef/FETCH_OUTPUT_INDICATOR'
export const FETCH_OUTPUT_INDICATOR_FAILURE = 'sef/FETCH_OUTPUT_INDICATOR_FAILURE'
export const FETCH_OUTPUT_INDICATOR_SUCCESS = 'sef/FETCH_OUTPUT_INDICATOR_SUCCESS'
export const FETCH_OUTPUT_INDICATORS = 'sef/FETCH_OUTPUT_INDICATORS'
export const FETCH_OUTPUT_INDICATORS_FAILURE = 'sef/FETCH_OUTPUT_INDICATORS_FAILURE'
export const FETCH_OUTPUT_INDICATORS_SUCCESS = 'sef/FETCH_OUTPUT_INDICATORS_SUCCESS'
export const FETCH_OUTPUTS = 'sef/FETCH_OUTPUTS'
export const FETCH_OUTPUTS_FAILURE = 'sef/FETCH_OUTPUTS_FAILURE'
export const FETCH_OUTPUTS_SUCCESS = 'sef/FETCH_OUTPUTS_SUCCESS'
export const FETCH_OWN_PROFILE_DETAILS = 'sef/FETCH_OWN_PROFILE_DETAILS'
export const FETCH_OWN_PROFILE_DETAILS_FAILURE = 'sef/FETCH_OWN_PROFILE_DETAILS_FAILURE'
export const FETCH_OWN_PROFILE_DETAILS_SUCCESS = 'sef/FETCH_OWN_PROFILE_DETAILS_SUCCESS'
export const FETCH_PARTNER = 'sef/FETCH_PARTNER'
export const FETCH_PARTNER_DISTRICT = 'sef/FETCH_PARTNER_DISTRICT'
export const FETCH_PARTNER_DISTRICT_FAILURE = 'sef/FETCH_PARTNER_DISTRICT_FAILURE'
export const FETCH_PARTNER_DISTRICT_SUCCESS = 'sef/FETCH_PARTNER_DISTRICT_SUCCESS'
export const FETCH_PARTNER_DISTRICTS = 'sef/FETCH_PARTNER_DISTRICTS'
export const FETCH_PARTNER_DISTRICTS_FAILURE = 'sef/FETCH_PARTNER_DISTRICTS_FAILURE'
export const FETCH_PARTNER_DISTRICTS_SUCCESS = 'sef/FETCH_PARTNER_DISTRICTS_SUCCESS'
export const FETCH_PARTNER_FAILURE = 'sef/FETCH_PARTNER_FAILURE'
export const FETCH_PARTNER_SUCCESS = 'sef/FETCH_PARTNER_SUCCESS'
export const FETCH_PARTNERS = 'sef/FETCH_PARTNERS'
export const FETCH_PARTNERS_FAILURE = 'sef/FETCH_PARTNERS_FAILURE'
export const FETCH_PARTNERS_SUCCESS = 'sef/FETCH_PARTNERS_SUCCESS'
export const FETCH_PAYROLL_HISTORY = 'sef/FETCH_PAYROLL_HISTORY'
export const FETCH_PAYROLL_HISTORY_FAILURE = 'sef/FETCH_PAYROLL_HISTORY_FAILURE'
export const FETCH_PAYROLL_HISTORY_SUCCESS = 'sef/FETCH_PAYROLL_HISTORY_SUCCESS'
export const FETCH_PAYROLL_PAYMENT_REQUEST = 'sef/FETCH_PAYROLL_PAYMENT_REQUEST'
export const FETCH_PAYROLL_PAYMENT_REQUEST_FAILURE = 'sef/FETCH_PAYROLL_PAYMENT_REQUEST_FAILURE'
export const FETCH_PAYROLL_PAYMENT_REQUEST_SUCCESS = 'sef/FETCH_PAYROLL_PAYMENT_REQUEST_SUCCESS'
export const FETCH_PLACE = 'sef/FETCH_PLACE'
export const FETCH_PLACE_FAILURE = 'sef/FETCH_PLACE_FAILURE'
export const FETCH_PLACE_SUCCESS = 'sef/FETCH_PLACE_SUCCESS'
export const FETCH_PLACES = 'sef/FETCH_PLACES'
export const FETCH_PLACES_FAILURE = 'sef/FETCH_PLACES_FAILURE'
export const FETCH_PLACES_SUCCESS = 'sef/FETCH_PLACES_SUCCESS'
export const FETCH_PURCHASE_REQUEST = 'sef/FETCH_PURCHASE_REQUEST'
export const FETCH_PURCHASE_REQUEST_FAILURE = 'sef/FETCH_PURCHASE_REQUEST_FAILURE'
export const FETCH_PURCHASE_REQUEST_SUCCESS = 'sef/FETCH_PURCHASE_REQUEST_SUCCESS'
export const FETCH_PURCHASE_REQUESTS = 'sef/FETCH_PURCHASE_REQUESTS'
export const FETCH_PURCHASE_REQUESTS_FAILURE = 'sef/FETCH_PURCHASE_REQUESTS_FAILURE'
export const FETCH_PURCHASE_REQUESTS_SUCCESS = 'sef/FETCH_PURCHASE_REQUESTS_SUCCESS'
export const FETCH_STATS = 'sef/FETCH_STATS'
export const FETCH_STATS_FAILURE = 'sef/FETCH_STATS_FAILURE'
export const FETCH_STATS_SUCCESS = 'sef/FETCH_STATS_SUCCESS'
export const FETCH_SUPPLIER = 'sef/FETCH_SUPPLIER'
export const FETCH_SUPPLIER_FAILURE = 'sef/FETCH_SUPPLIER_FAILURE'
export const FETCH_SUPPLIER_SUCCESS = 'sef/FETCH_SUPPLIER_SUCCESS'
export const FETCH_SUPPLIERS = 'sef/FETCH_SUPPLIERS'
export const FETCH_SUPPLIERS_FAILURE = 'sef/FETCH_SUPPLIERS_FAILURE'
export const FETCH_SUPPLIERS_SUCCESS = 'sef/FETCH_SUPPLIERS_SUCCESS'
export const FETCH_TIMEKEEPERS = 'sef/FETCH_TIMEKEEPERS'
export const FETCH_TIMEKEEPERS_FAILURE = 'sef/FETCH_TIMEKEEPERS_FAILURE'
export const FETCH_TIMEKEEPERS_SUCCESS = 'sef/FETCH_TIMEKEEPERS_SUCCESS'
export const FETCH_WARD = 'sef/FETCH_WARD'
export const FETCH_WARD_FAILURE = 'sef/FETCH_WARD_FAILURE'
export const FETCH_WARD_SUCCESS = 'sef/FETCH_WARD_SUCCESS'
export const FETCH_WARDS = 'sef/FETCH_WARDS'
export const FETCH_WARDS_FAILURE = 'sef/FETCH_WARDS_FAILURE'
export const FETCH_WARDS_SUCCESS = 'sef/FETCH_WARDS_SUCCESS'
export const FETCH_WORKSITE = 'sef/FETCH_WORKSITE'
export const FETCH_WORKSITE_FAILURE = 'sef/FETCH_WORKSITE_FAILURE'
export const FETCH_WORKSITE_SUCCESS = 'sef/FETCH_WORKSITE_SUCCESS'
export const FETCH_WORKSITES = 'sef/FETCH_WORKSITES'
export const FETCH_WORKSITES_FAILURE = 'sef/FETCH_WORKSITES_FAILURE'
export const FETCH_WORKSITES_SUCCESS = 'sef/FETCH_WORKSITES_SUCCESS'
export const FETCH_WORKSPACE = 'sef/FETCH_WORKSPACE'
export const FETCH_WORKSPACE_FAILURE = 'sef/FETCH_WORKSPACE_FAILURE'
export const FETCH_WORKSPACE_SUCCESS = 'sef/FETCH_WORKSPACE_SUCCESS'
export const FETCH_WORKSPACES = 'sef/FETCH_WORKSPACES'
export const FETCH_WORKSPACES_FAILURE = 'sef/FETCH_WORKSPACES_FAILURE'
export const FETCH_WORKSPACES_SUCCESS = 'sef/FETCH_WORKSPACES_SUCCESS'
export const IMPORT_PARTICIPANTS = 'sef/IMPORT_PARTICIPANTS'
export const IMPORT_PARTICIPANTS_FAILURE = 'sef/IMPORT_PARTICIPANTS_FAILURE'
export const IMPORT_PARTICIPANTS_SUCCESS = 'sef/IMPORT_PARTICIPANTS_SUCCESS'
export const INITIATE_PAYROLL_REQUEST = 'sef/INITIATE_PAYROLL_REQUEST'
export const INITIATE_PAYROLL_REQUEST_FAILURE = 'sef/INITIATE_PAYROLL_REQUEST_FAILURE'
export const INITIATE_PAYROLL_REQUEST_SUCCESS = 'sef/INITIATE_PAYROLL_REQUEST_SUCCESS'
export const ONBOARD_EMPLOYEE = 'sef/ONBOARD_EMPLOYEE'
export const ONBOARD_EMPLOYEE_FAILURE = 'sef/ONBOARD_EMPLOYEE_FAILURE'
export const ONBOARD_EMPLOYEE_SUCCESS = 'sef/ONBOARD_EMPLOYEE_SUCCESS'
export const REQUEST_AGREEMENT_ZIP = 'sef/REQUEST_AGREEMENT_ZIP'
export const REQUEST_AGREEMENT_ZIP_SUCCESS = 'sef/REQUEST_AGREEMENT_ZIP_SUCCESS'
export const REQUEST_AGREEMENT_ZIP_FAILURE = 'sef/REQUEST_AGREEMENT_ZIP_FAILURE'
export const SEND_OUTCOMES_TRACKING_REMINDER = 'sef/SEND_OUTCOMES_TRACKING_REMINDER'
export const SEND_OUTCOMES_TRACKING_REMINDER_FAILURE = 'sef/SEND_OUTCOMES_TRACKING_REMINDER_FAILURE'
export const SEND_OUTCOMES_TRACKING_REMINDER_SUCCESS = 'sef/SEND_OUTCOMES_TRACKING_REMINDER_SUCCESS'
export const SUBMIT_INDICATOR_OUTCOMES = 'sef/SUBMIT_INDICATOR_OUTCOMES'
export const SUBMIT_INDICATOR_OUTCOMES_FAILURE = 'sef/SUBMIT_INDICATOR_OUTCOMES_FAILURE'
export const SUBMIT_INDICATOR_OUTCOMES_SUCCESS = 'sef/SUBMIT_INDICATOR_OUTCOMES_SUCCESS'
export const UPDATE_BUDGET_CATEGORY = 'sef/UPDATE_BUDGET_CATEGORY'
export const UPDATE_BUDGET_CATEGORY_FAILURE = 'sef/UPDATE_BUDGET_CATEGORY_FAILURE'
export const UPDATE_BUDGET_CATEGORY_SUCCESS = 'sef/UPDATE_BUDGET_CATEGORY_SUCCESS'
export const UPDATE_MUNICIPALITY = 'sef/UPDATE_MUNICIPALITY'
export const UPDATE_MUNICIPALITY_FAILURE = 'sef/UPDATE_MUNICIPALITY_FAILURE'
export const UPDATE_MUNICIPALITY_SUCCESS = 'sef/UPDATE_MUNICIPALITY_SUCCESS'
export const UPDATE_AGREEMENT_TEMPLATE = 'sef/UPDATE_AGREEMENT_TEMPLATE'
export const UPDATE_AGREEMENT_TEMPLATE_FAILURE = 'sef/UPDATE_AGREEMENT_TEMPLATE_FAILURE'
export const UPDATE_AGREEMENT_TEMPLATE_SUCCESS = 'sef/UPDATE_AGREEMENT_TEMPLATE_SUCCESS'
export const UPDATE_OUTPUT = 'sef/UPDATE_OUTPUT'
export const UPDATE_OUTPUT_FAILURE = 'sef/UPDATE_OUTPUT_FAILURE'
export const UPDATE_OUTPUT_SUCCESS = 'sef/UPDATE_OUTPUT_SUCCESS'
export const UPDATE_OUTPUT_AREA = 'sef/UPDATE_OUTPUT_AREA'
export const UPDATE_OUTPUT_AREA_FAILURE = 'sef/UPDATE_OUTPUT_AREA_FAILURE'
export const UPDATE_OUTPUT_AREA_SUCCESS = 'sef/UPDATE_OUTPUT_AREA_SUCCESS'
export const UPDATE_OUTPUT_INDICATOR = 'sef/UPDATE_OUTPUT_INDICATOR'
export const UPDATE_OUTPUT_INDICATOR_FAILURE = 'sef/UPDATE_OUTPUT_INDICATOR_FAILURE'
export const UPDATE_OUTPUT_INDICATOR_SUCCESS = 'sef/UPDATE_OUTPUT_INDICATOR_SUCCESS'
export const UPDATE_PARTNER_DISTRICT = 'sef/UPDATE_PARTNER_DISTRICT'
export const UPDATE_PARTNER_DISTRICT_FAILURE = 'sef/UPDATE_PARTNER_DISTRICT_FAILURE'
export const UPDATE_PARTNER_DISTRICT_SUCCESS = 'sef/UPDATE_PARTNER_DISTRICT_SUCCESS'
export const UPDATE_PAYROLL_REQUEST = 'sef/UPDATE_PAYROLL_REQUEST'
export const UPDATE_PAYROLL_REQUEST_FAILURE = 'sef/UPDATE_PAYROLL_REQUEST_FAILURE'
export const UPDATE_PAYROLL_REQUEST_SUCCESS = 'sef/UPDATE_PAYROLL_REQUEST_SUCCESS'
export const UPDATE_PLACE = 'sef/UPDATE_PLACE'
export const UPDATE_PLACE_FAILURE = 'sef/UPDATE_PLACE_FAILURE'
export const UPDATE_PLACE_SUCCESS = 'sef/UPDATE_PLACE_SUCCESS'
export const UPDATE_PURCHASE_REQUEST = 'sef/UPDATE_PURCHASE_REQUEST'
export const UPDATE_PURCHASE_REQUEST_FAILURE = 'sef/UPDATE_PURCHASE_REQUEST_FAILURE'
export const UPDATE_PURCHASE_REQUEST_SUCCESS = 'sef/UPDATE_PURCHASE_REQUEST_SUCCESS'
export const UPDATE_SUPPLIER = 'sef/UPDATE_SUPPLIER'
export const UPDATE_SUPPLIER_FAILURE = 'sef/UPDATE_SUPPLIER_FAILURE'
export const UPDATE_SUPPLIER_SUCCESS = 'sef/UPDATE_SUPPLIER_SUCCESS'
export const UPDATE_WARD = 'sef/UPDATE_WARD'
export const UPDATE_WARD_FAILURE = 'sef/UPDATE_WARD_FAILURE'
export const UPDATE_WARD_SUCCESS = 'sef/UPDATE_WARD_SUCCESS'
export const UPDATE_WORKSITE = 'sef/UPDATE_WORKSITE'
export const UPDATE_WORKSITE_FAILURE = 'sef/UPDATE_WORKSITE_FAILURE'
export const UPDATE_WORKSITE_SUCCESS = 'sef/UPDATE_WORKSITE_SUCCESS'
export const UPDATE_WORKSPACE = 'sef/UPDATE_WORKSPACE'
export const UPDATE_WORKSPACE_FAILURE = 'sef/UPDATE_WORKSPACE_FAILURE'
export const UPDATE_WORKSPACE_SUCCESS = 'sef/UPDATE_WORKSPACE_SUCCESS'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  sef: {
    createAgreementTemplate,
    createAgreementTemplateSuccess,
    createAgreementTemplateFailure,
    createBudget,
    createBudgetSuccess,
    createBudgetFailure,
    createBudgetCategory,
    createBudgetCategorySuccess,
    createBudgetCategoryFailure,
    createOutputArea,
    createOutputAreaSuccess,
    createOutputAreaFailure,
    createOutputIndicator,
    createOutputIndicatorSuccess,
    createOutputIndicatorFailure,
    createPartner,
    createPartnerSuccess,
    createPartnerFailure,
    createPartnerDistrict,
    createPartnerDistrictSuccess,
    createPartnerDistrictFailure,
    createPurchaseRequest,
    createPurchaseRequestSuccess,
    createPurchaseRequestFailure,
    createSupplier,
    createSupplierSuccess,
    createSupplierFailure,
    createWorksite,
    createWorksiteSuccess,
    createWorksiteFailure,
    createWorkspace,
    createWorkspaceSuccess,
    createWorkspaceFailure,
    importParticipants,
    importParticipantsSuccess,
    importParticipantsFailure,
    initiatePayrollRequest,
    initiatePayrollRequestSuccess,
    initiatePayrollRequestFailure,
    deleteBudgetCategory,
    deleteBudgetCategorySuccess,
    deleteBudgetCategoryFailure,
    deleteOutput,
    deleteOutputSuccess,
    deleteOutputFailure,
    deleteOutputArea,
    deleteOutputAreaSuccess,
    deleteOutputAreaFailure,
    deleteOutputIndicator,
    deleteOutputIndicatorSuccess,
    deleteOutputIndicatorFailure,
    deletePartnerDistrict,
    deletePartnerDistrictSuccess,
    deletePartnerDistrictFailure,
    deletePurchaseRequest,
    deletePurchaseRequestSuccess,
    deletePurchaseRequestFailure,
    deleteSupplier,
    deleteSupplierSuccess,
    deleteSupplierFailure,
    deleteWorksite,
    deleteWorksiteSuccess,
    deleteWorksiteFailure,
    deleteWorkspace,
    deleteWorkspaceSuccess,
    deleteWorkspaceFailure,
    fetchAgreementTemplate,
    fetchAgreementTemplateSuccess,
    fetchAgreementTemplateFailure,
    fetchAgreementTemplates,
    fetchAgreementTemplatesSuccess,
    fetchAgreementTemplatesFailure,
    fetchBudgetCategory,
    fetchBudgetCategorySuccess,
    fetchBudgetCategoryFailure,
    fetchBudgetCategories,
    fetchBudgetCategoriesSuccess,
    fetchBudgetCategoriesFailure,
    fetchDistricts,
    fetchDistrictsSuccess,
    fetchDistrictsFailure,
    fetchEmployeeDetails,
    fetchEmployeeDetailsSuccess,
    fetchEmployeeDetailsFailure,
    fetchMunicipality,
    fetchMunicipalitySuccess,
    fetchMunicipalityFailure,
    fetchMunicipalities,
    fetchMunicipalitiesSuccess,
    fetchMunicipalitiesFailure,
    fetchOnboardingQuestions,
    fetchOnboardingQuestionsSuccess,
    fetchOnboardingQuestionsFailure,
    fetchOutputArea,
    fetchOutputAreaSuccess,
    fetchOutputAreaFailure,
    fetchOutputAreas,
    fetchOutputAreasSuccess,
    fetchOutputAreasFailure,
    fetchOutput,
    fetchOutputSuccess,
    fetchOutputFailure,
    fetchOutputs,
    fetchOutputsSuccess,
    fetchOutputsFailure,
    fetchOutputIndicator,
    fetchOutputIndicatorSuccess,
    fetchOutputIndicatorFailure,
    fetchOutputIndicators,
    fetchOutputIndicatorsSuccess,
    fetchOutputIndicatorsFailure,
    fetchOwnProfileDetails,
    fetchOwnProfileDetailsSuccess,
    fetchOwnProfileDetailsFailure,
    fetchPartnerDistrict,
    fetchPartnerDistrictSuccess,
    fetchPartnerDistrictFailure,
    fetchPartnerDistricts,
    fetchPartnerDistrictsSuccess,
    fetchPartnerDistrictsFailure,
    fetchPartners,
    fetchPartnersSuccess,
    fetchPartnersFailure,
    fetchPayrollHistory,
    fetchPayrollHistorySuccess,
    fetchPayrollHistoryFailure,
    fetchPayrollPaymentRequest,
    fetchPayrollPaymentRequestSuccess,
    fetchPayrollPaymentRequestFailure,
    fetchPlace,
    fetchPlaceSuccess,
    fetchPlaceFailure,
    fetchPlaces,
    fetchPlacesSuccess,
    fetchPlacesFailure,
    fetchPurchaseRequest,
    fetchPurchaseRequestSuccess,
    fetchPurchaseRequestFailure,
    fetchPurchaseRequests,
    fetchPurchaseRequestsSuccess,
    fetchPurchaseRequestsFailure,
    fetchStats,
    fetchStatsSuccess,
    fetchStatsFailure,
    fetchSupplier,
    fetchSupplierSuccess,
    fetchSupplierFailure,
    fetchSuppliers,
    fetchSuppliersSuccess,
    fetchSuppliersFailure,
    fetchTimekeepers,
    fetchTimekeepersSuccess,
    fetchTimekeepersFailure,
    fetchWard,
    fetchWardSuccess,
    fetchWardFailure,
    fetchWards,
    fetchWardsSuccess,
    fetchWardsFailure,
    fetchWorksite,
    fetchWorksiteSuccess,
    fetchWorksiteFailure,
    fetchWorksites,
    fetchWorksitesSuccess,
    fetchWorksitesFailure,
    fetchWorkspace,
    fetchWorkspaceSuccess,
    fetchWorkspaceFailure,
    fetchWorkspaces,
    fetchWorkspacesSuccess,
    fetchWorkspacesFailure,
    onboardEmployee,
    onboardEmployeeSuccess,
    onboardEmployeeFailure,
    requestAgreementZip,
    requestAgreementZipFailure,
    requestAgreementZipSuccess,
    sendOutcomesTrackingReminder,
    sendOutcomesTrackingReminderSuccess,
    sendOutcomesTrackingReminderFailure,
    submitIndicatorOutcomes,
    submitIndicatorOutcomesSuccess,
    submitIndicatorOutcomesFailure,
    updateAgreementTemplate,
    updateAgreementTemplateSuccess,
    updateAgreementTemplateFailure,
    updateBudgetCategory,
    updateBudgetCategorySuccess,
    updateBudgetCategoryFailure,
    updateMunicipality,
    updateMunicipalitySuccess,
    updateMunicipalityFailure,
    updateOutput,
    updateOutputSuccess,
    updateOutputFailure,
    updateOutputArea,
    updateOutputAreaSuccess,
    updateOutputAreaFailure,
    updateOutputIndicator,
    updateOutputIndicatorSuccess,
    updateOutputIndicatorFailure,
    updatePartnerDistrict,
    updatePartnerDistrictSuccess,
    updatePartnerDistrictFailure,
    updatePayrollRequest,
    updatePayrollRequestSuccess,
    updatePayrollRequestFailure,
    updatePlace,
    updatePlaceSuccess,
    updatePlaceFailure,
    updatePurchaseRequest,
    updatePurchaseRequestSuccess,
    updatePurchaseRequestFailure,
    updateSupplier,
    updateSupplierSuccess,
    updateSupplierFailure,
    updateWard,
    updateWardSuccess,
    updateWardFailure,
    updateWorksite,
    updateWorksiteSuccess,
    updateWorksiteFailure,
    updateWorkspace,
    updateWorkspaceSuccess,
    updateWorkspaceFailure
  }
} = createActions({
  [CREATE_AGREEMENT_TEMPLATE]: undefined,
  [CREATE_AGREEMENT_TEMPLATE_FAILURE]: undefined,
  [CREATE_AGREEMENT_TEMPLATE_SUCCESS]: undefined,
  [CREATE_BUDGET]: undefined,
  [CREATE_BUDGET_FAILURE]: undefined,
  [CREATE_BUDGET_SUCCESS]: undefined,
  [CREATE_BUDGET_CATEGORY]: undefined,
  [CREATE_BUDGET_CATEGORY_FAILURE]: undefined,
  [CREATE_BUDGET_CATEGORY_SUCCESS]: undefined,
  [CREATE_OUTPUT_AREA]: undefined,
  [CREATE_OUTPUT_AREA_FAILURE]: undefined,
  [CREATE_OUTPUT_AREA_SUCCESS]: undefined,
  [CREATE_OUTPUT_INDICATOR]: undefined,
  [CREATE_OUTPUT_INDICATOR_FAILURE]: undefined,
  [CREATE_OUTPUT_INDICATOR_SUCCESS]: undefined,
  [CREATE_PARTNER]: undefined,
  [CREATE_PARTNER_FAILURE]: undefined,
  [CREATE_PARTNER_SUCCESS]: undefined,
  [CREATE_PARTNER_DISTRICT]: undefined,
  [CREATE_PARTNER_DISTRICT_FAILURE]: undefined,
  [CREATE_PARTNER_DISTRICT_SUCCESS]: undefined,
  [CREATE_PURCHASE_REQUEST]: undefined,
  [CREATE_PURCHASE_REQUEST_FAILURE]: undefined,
  [CREATE_PURCHASE_REQUEST_SUCCESS]: undefined,
  [CREATE_SUPPLIER]: undefined,
  [CREATE_SUPPLIER_FAILURE]: undefined,
  [CREATE_SUPPLIER_SUCCESS]: undefined,
  [CREATE_WORKSITE]: undefined,
  [CREATE_WORKSITE_FAILURE]: undefined,
  [CREATE_WORKSITE_SUCCESS]: undefined,
  [CREATE_WORKSPACE]: undefined,
  [CREATE_WORKSPACE_FAILURE]: undefined,
  [CREATE_WORKSPACE_SUCCESS]: undefined,
  [DELETE_BUDGET_CATEGORY]: undefined,
  [DELETE_BUDGET_CATEGORY_FAILURE]: undefined,
  [DELETE_BUDGET_CATEGORY_SUCCESS]: undefined,
  [DELETE_OUTPUT]: undefined,
  [DELETE_OUTPUT_FAILURE]: undefined,
  [DELETE_OUTPUT_SUCCESS]: undefined,
  [DELETE_OUTPUT_AREA]: undefined,
  [DELETE_OUTPUT_AREA_FAILURE]: undefined,
  [DELETE_OUTPUT_AREA_SUCCESS]: undefined,
  [DELETE_OUTPUT_INDICATOR]: undefined,
  [DELETE_OUTPUT_INDICATOR_FAILURE]: undefined,
  [DELETE_OUTPUT_INDICATOR_SUCCESS]: undefined,
  [DELETE_PARTNER_DISTRICT]: undefined,
  [DELETE_PARTNER_DISTRICT_FAILURE]: undefined,
  [DELETE_PARTNER_DISTRICT_SUCCESS]: undefined,
  [DELETE_PURCHASE_REQUEST]: undefined,
  [DELETE_PURCHASE_REQUEST_FAILURE]: undefined,
  [DELETE_PURCHASE_REQUEST_SUCCESS]: undefined,
  [DELETE_SUPPLIER]: undefined,
  [DELETE_SUPPLIER_FAILURE]: undefined,
  [DELETE_SUPPLIER_SUCCESS]: undefined,
  [DELETE_WORKSITE]: undefined,
  [DELETE_WORKSITE_FAILURE]: undefined,
  [DELETE_WORKSITE_SUCCESS]: undefined,
  [DELETE_WORKSPACE]: undefined,
  [DELETE_WORKSPACE_FAILURE]: undefined,
  [DELETE_WORKSPACE_SUCCESS]: undefined,
  [FETCH_AGREEMENT_TEMPLATE]: undefined,
  [FETCH_AGREEMENT_TEMPLATE_FAILURE]: undefined,
  [FETCH_AGREEMENT_TEMPLATE_SUCCESS]: undefined,
  [FETCH_AGREEMENT_TEMPLATES]: undefined,
  [FETCH_AGREEMENT_TEMPLATES_FAILURE]: undefined,
  [FETCH_AGREEMENT_TEMPLATES_SUCCESS]: undefined,
  [FETCH_BUDGET_CATEGORY]: undefined,
  [FETCH_BUDGET_CATEGORY_FAILURE]: undefined,
  [FETCH_BUDGET_CATEGORY_SUCCESS]: undefined,
  [FETCH_BUDGET_CATEGORIES]: undefined,
  [FETCH_BUDGET_CATEGORIES_FAILURE]: undefined,
  [FETCH_BUDGET_CATEGORIES_SUCCESS]: undefined,
  [FETCH_DISTRICTS]: undefined,
  [FETCH_DISTRICTS_FAILURE]: undefined,
  [FETCH_DISTRICTS_SUCCESS]: undefined,
  [FETCH_EMPLOYEE_DETAILS]: undefined,
  [FETCH_EMPLOYEE_DETAILS_FAILURE]: undefined,
  [FETCH_EMPLOYEE_DETAILS_SUCCESS]: undefined,
  [FETCH_MUNICIPALITY]: undefined,
  [FETCH_MUNICIPALITY_FAILURE]: undefined,
  [FETCH_MUNICIPALITY_SUCCESS]: undefined,
  [FETCH_MUNICIPALITIES]: undefined,
  [FETCH_MUNICIPALITIES_FAILURE]: undefined,
  [FETCH_MUNICIPALITIES_SUCCESS]: undefined,
  [FETCH_ONBOARDING_QUESTIONS]: undefined,
  [FETCH_ONBOARDING_QUESTIONS_SUCCESS]: undefined,
  [FETCH_ONBOARDING_QUESTIONS_FAILURE]: undefined,
  [FETCH_OUTPUT]: undefined,
  [FETCH_OUTPUT_FAILURE]: undefined,
  [FETCH_OUTPUT_SUCCESS]: undefined,
  [FETCH_OUTPUTS]: undefined,
  [FETCH_OUTPUTS_FAILURE]: undefined,
  [FETCH_OUTPUTS_SUCCESS]: undefined,
  [FETCH_OUTPUT_AREA]: undefined,
  [FETCH_OUTPUT_AREA_FAILURE]: undefined,
  [FETCH_OUTPUT_AREA_SUCCESS]: undefined,
  [FETCH_OUTPUT_AREAS]: undefined,
  [FETCH_OUTPUT_AREAS_FAILURE]: undefined,
  [FETCH_OUTPUT_AREAS_SUCCESS]: undefined,
  [FETCH_OUTPUT_INDICATOR]: undefined,
  [FETCH_OUTPUT_INDICATOR_FAILURE]: undefined,
  [FETCH_OUTPUT_INDICATOR_SUCCESS]: undefined,
  [FETCH_OUTPUT_INDICATORS]: undefined,
  [FETCH_OUTPUT_INDICATORS_FAILURE]: undefined,
  [FETCH_OUTPUT_INDICATORS_SUCCESS]: undefined,
  [FETCH_OWN_PROFILE_DETAILS]: undefined,
  [FETCH_OWN_PROFILE_DETAILS_SUCCESS]: undefined,
  [FETCH_OWN_PROFILE_DETAILS_FAILURE]: undefined,
  [FETCH_PARTNER_DISTRICT]: undefined,
  [FETCH_PARTNER_DISTRICT_FAILURE]: undefined,
  [FETCH_PARTNER_DISTRICT_SUCCESS]: undefined,
  [FETCH_PARTNER_DISTRICTS]: undefined,
  [FETCH_PARTNER_DISTRICTS_FAILURE]: undefined,
  [FETCH_PARTNER_DISTRICTS_SUCCESS]: undefined,
  [FETCH_PARTNERS]: undefined,
  [FETCH_PARTNERS_FAILURE]: undefined,
  [FETCH_PARTNERS_SUCCESS]: undefined,
  [FETCH_PAYROLL_HISTORY]: undefined,
  [FETCH_PAYROLL_HISTORY_FAILURE]: undefined,
  [FETCH_PAYROLL_HISTORY_SUCCESS]: undefined,
  [FETCH_PAYROLL_PAYMENT_REQUEST]: undefined,
  [FETCH_PAYROLL_PAYMENT_REQUEST_FAILURE]: undefined,
  [FETCH_PAYROLL_PAYMENT_REQUEST_SUCCESS]: undefined,
  [FETCH_PLACE]: undefined,
  [FETCH_PLACE_FAILURE]: undefined,
  [FETCH_PLACE_SUCCESS]: undefined,
  [FETCH_PLACES]: undefined,
  [FETCH_PLACES_FAILURE]: undefined,
  [FETCH_PLACES_SUCCESS]: undefined,
  [FETCH_PURCHASE_REQUEST]: undefined,
  [FETCH_PURCHASE_REQUEST_FAILURE]: undefined,
  [FETCH_PURCHASE_REQUEST_SUCCESS]: undefined,
  [FETCH_PURCHASE_REQUESTS]: undefined,
  [FETCH_PURCHASE_REQUESTS_FAILURE]: undefined,
  [FETCH_PURCHASE_REQUESTS_SUCCESS]: undefined,
  [FETCH_STATS]: undefined,
  [FETCH_STATS_FAILURE]: undefined,
  [FETCH_STATS_SUCCESS]: undefined,
  [FETCH_SUPPLIER]: undefined,
  [FETCH_SUPPLIER_FAILURE]: undefined,
  [FETCH_SUPPLIER_SUCCESS]: undefined,
  [FETCH_SUPPLIERS]: undefined,
  [FETCH_SUPPLIERS_FAILURE]: undefined,
  [FETCH_SUPPLIERS_SUCCESS]: undefined,
  [FETCH_TIMEKEEPERS]: undefined,
  [FETCH_TIMEKEEPERS_FAILURE]: undefined,
  [FETCH_TIMEKEEPERS_SUCCESS]: undefined,
  [FETCH_WARD]: undefined,
  [FETCH_WARD_FAILURE]: undefined,
  [FETCH_WARD_SUCCESS]: undefined,
  [FETCH_WARDS]: undefined,
  [FETCH_WARDS_FAILURE]: undefined,
  [FETCH_WARDS_SUCCESS]: undefined,
  [FETCH_WORKSITE]: undefined,
  [FETCH_WORKSITE_FAILURE]: undefined,
  [FETCH_WORKSITE_SUCCESS]: undefined,
  [FETCH_WORKSITES]: undefined,
  [FETCH_WORKSITES_FAILURE]: undefined,
  [FETCH_WORKSITES_SUCCESS]: undefined,
  [FETCH_WORKSPACE]: undefined,
  [FETCH_WORKSPACE_FAILURE]: undefined,
  [FETCH_WORKSPACE_SUCCESS]: undefined,
  [FETCH_WORKSPACES]: undefined,
  [FETCH_WORKSPACES_FAILURE]: undefined,
  [FETCH_WORKSPACES_SUCCESS]: undefined,
  [IMPORT_PARTICIPANTS]: undefined,
  [IMPORT_PARTICIPANTS_FAILURE]: undefined,
  [IMPORT_PARTICIPANTS_SUCCESS]: undefined,
  [INITIATE_PAYROLL_REQUEST]: undefined,
  [INITIATE_PAYROLL_REQUEST_FAILURE]: undefined,
  [INITIATE_PAYROLL_REQUEST_SUCCESS]: undefined,
  [ONBOARD_EMPLOYEE]: undefined,
  [ONBOARD_EMPLOYEE_FAILURE]: undefined,
  [ONBOARD_EMPLOYEE_SUCCESS]: undefined,
  [REQUEST_AGREEMENT_ZIP]: undefined,
  [REQUEST_AGREEMENT_ZIP_FAILURE]: undefined,
  [REQUEST_AGREEMENT_ZIP_SUCCESS]: undefined,
  [SEND_OUTCOMES_TRACKING_REMINDER]: undefined,
  [SEND_OUTCOMES_TRACKING_REMINDER_FAILURE]: undefined,
  [SEND_OUTCOMES_TRACKING_REMINDER_SUCCESS]: undefined,
  [SUBMIT_INDICATOR_OUTCOMES]: undefined,
  [SUBMIT_INDICATOR_OUTCOMES_FAILURE]: undefined,
  [SUBMIT_INDICATOR_OUTCOMES_SUCCESS]: undefined,
  [UPDATE_AGREEMENT_TEMPLATE]: undefined,
  [UPDATE_AGREEMENT_TEMPLATE_FAILURE]: undefined,
  [UPDATE_AGREEMENT_TEMPLATE_SUCCESS]: undefined,
  [UPDATE_BUDGET_CATEGORY]: undefined,
  [UPDATE_BUDGET_CATEGORY_FAILURE]: undefined,
  [UPDATE_BUDGET_CATEGORY_SUCCESS]: undefined,
  [UPDATE_MUNICIPALITY]: undefined,
  [UPDATE_MUNICIPALITY_FAILURE]: undefined,
  [UPDATE_MUNICIPALITY_SUCCESS]: undefined,
  [UPDATE_OUTPUT]: undefined,
  [UPDATE_OUTPUT_FAILURE]: undefined,
  [UPDATE_OUTPUT_SUCCESS]: undefined,
  [UPDATE_OUTPUT_AREA]: undefined,
  [UPDATE_OUTPUT_AREA_FAILURE]: undefined,
  [UPDATE_OUTPUT_AREA_SUCCESS]: undefined,
  [UPDATE_OUTPUT_INDICATOR]: undefined,
  [UPDATE_OUTPUT_INDICATOR_FAILURE]: undefined,
  [UPDATE_OUTPUT_INDICATOR_SUCCESS]: undefined,
  [UPDATE_PARTNER_DISTRICT]: undefined,
  [UPDATE_PARTNER_DISTRICT_FAILURE]: undefined,
  [UPDATE_PARTNER_DISTRICT_SUCCESS]: undefined,
  [UPDATE_PAYROLL_REQUEST]: undefined,
  [UPDATE_PAYROLL_REQUEST_FAILURE]: undefined,
  [UPDATE_PAYROLL_REQUEST_SUCCESS]: undefined,
  [UPDATE_PLACE]: undefined,
  [UPDATE_PLACE_FAILURE]: undefined,
  [UPDATE_PLACE_SUCCESS]: undefined,
  [UPDATE_PURCHASE_REQUEST]: undefined,
  [UPDATE_PURCHASE_REQUEST_FAILURE]: undefined,
  [UPDATE_PURCHASE_REQUEST_SUCCESS]: undefined,
  [UPDATE_SUPPLIER]: undefined,
  [UPDATE_SUPPLIER_FAILURE]: undefined,
  [UPDATE_SUPPLIER_SUCCESS]: undefined,
  [UPDATE_WARD]: undefined,
  [UPDATE_WARD_FAILURE]: undefined,
  [UPDATE_WARD_SUCCESS]: undefined,
  [UPDATE_WORKSITE]: undefined,
  [UPDATE_WORKSITE_FAILURE]: undefined,
  [UPDATE_WORKSITE_SUCCESS]: undefined,
  [UPDATE_WORKSPACE]: undefined,
  [UPDATE_WORKSPACE_FAILURE]: undefined,
  [UPDATE_WORKSPACE_SUCCESS]: undefined
})

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  onboardingQuestionsData: [],
  ownProfileDetails: {},
  employeeDetails: {},
  paginatedAgreementTemplatesData: {
    agreementTemplates: [],
    agreementTemplatesCount: 0,
    agreementTemplatesPageCount: 0
  },
  paginatedBudgetCategoriesData: {
    budgetCategories: [],
    budgetCategoriesCount: 0,
    budgetCategoriesPageCount: 0
  },
  paginatedDistrictsData: { districts: [], districtsCount: 0, districtsPageCount: 0 },
  paginatedMunicipalitiesData: {
    municipalities: [],
    municipalitiesCount: 0,
    municipalitiesPageCount: 0
  },
  paginatedOutputsData: {
    outputs: [],
    outputsCount: 0,
    outputsPageCount: 0
  },
  paginatedOutputAreasData: {
    outputAreas: [],
    outputAreasCount: 0,
    outputAreasPageCount: 0
  },
  paginatedOutputIndicatorsData: {
    outputIndicators: [],
    outputIndicatorsCount: 0,
    outputIndicatorsPageCount: 0
  },
  paginatedPartnerDistrictsData: {
    partnerDistricts: [],
    partnerDistrictsCount: 0,
    partnerDistrictsPageCount: 0
  },
  paginatedPartnersData: { partners: [], partnersCount: 0, partnersPageCount: 0 },
  paginatedPayrollData: { payroll: [], payrollCount: 0, payrollPageCount: 0 },
  paginatedPlacesData: { places: [], placesCount: 0, placesPageCount: 0 },
  paginatedPurchaseRequestsData: {
    purchaseRequests: [],
    purchaseRequestsCount: 0,
    purchaseRequestsPageCount: 0
  },
  paginatedSuppliersData: { suppliers: [], suppliersCount: 0, suppliersPageCount: 0 },
  paginatedWardsData: { wards: [], wardsCount: 0, wardsPageCount: 0 },
  paginatedWorksitesData: { worksites: [], worksitesCount: 0, worksitesPageCount: 0 },
  paginatedWorkspacesData: { workspaces: [], workspacesCount: 0, workspacesPageCount: 0 },
  provincesData: [],
  timekeepersData: [],
  singleAgreementTemplateData: {},
  singleBudgetCategoryData: {},
  singleMunicipalityData: {},
  requestedZipFile: null,
  payrollObject: null,
  singleOutputAreaData: {},
  singleOutputData: {},
  singleOutputIndicatorData: {},
  singlePartnerData: {},
  singlePartnerDistrictData: {},
  singlePartnerDistrictError: null,
  singlePlaceData: {},
  singlePurchaseRequestData: {},
  singleSupplierData: {},
  singleWardData: {},
  singleWorksiteData: {},
  singleWorkspaceData: {},
  statsData: {}
}

export default handleActions(
  {
    [CREATE_AGREEMENT_TEMPLATE]: startSubmitting,
    [CREATE_AGREEMENT_TEMPLATE_FAILURE]: loadingError,
    [CREATE_AGREEMENT_TEMPLATE_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_BUDGET]: startSubmitting,
    [CREATE_BUDGET_FAILURE]: loadingError,
    [CREATE_BUDGET_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_BUDGET_CATEGORY]: startSubmitting,
    [CREATE_BUDGET_CATEGORY_FAILURE]: loadingError,
    [CREATE_BUDGET_CATEGORY_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_OUTPUT_AREA]: startSubmitting,
    [CREATE_OUTPUT_AREA_FAILURE]: loadingError,
    [CREATE_OUTPUT_AREA_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_OUTPUT_INDICATOR]: startSubmitting,
    [CREATE_OUTPUT_INDICATOR_FAILURE]: loadingError,
    [CREATE_OUTPUT_INDICATOR_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_PARTNER]: startSubmitting,
    [CREATE_PARTNER_FAILURE]: loadingError,
    [CREATE_PARTNER_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_PARTNER_DISTRICT]: startSubmitting,
    [CREATE_PARTNER_DISTRICT_FAILURE]: loadingError,
    [CREATE_PARTNER_DISTRICT_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_PURCHASE_REQUEST]: startSubmitting,
    [CREATE_PURCHASE_REQUEST_FAILURE]: loadingError,
    [CREATE_PURCHASE_REQUEST_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_SUPPLIER]: startSubmitting,
    [CREATE_SUPPLIER_FAILURE]: loadingError,
    [CREATE_SUPPLIER_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_WORKSITE]: startSubmitting,
    [CREATE_WORKSITE_FAILURE]: loadingError,
    [CREATE_WORKSITE_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [CREATE_WORKSPACE]: startSubmitting,
    [CREATE_WORKSPACE_FAILURE]: loadingError,
    [CREATE_WORKSPACE_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_BUDGET_CATEGORY]: state => ({ ...state, isLoading: true }),
    [DELETE_BUDGET_CATEGORY_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_BUDGET_CATEGORY_FAILURE]: state => ({ ...state, isLoading: false }),
    [DELETE_OUTPUT]: state => ({ ...state, isLoading: true }),
    [DELETE_OUTPUT_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_OUTPUT_FAILURE]: state => ({ ...state, isLoading: false }),
    [DELETE_OUTPUT_AREA]: state => ({ ...state, isLoading: true }),
    [DELETE_OUTPUT_AREA_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_OUTPUT_AREA_FAILURE]: state => ({ ...state, isLoading: false }),
    [DELETE_OUTPUT_INDICATOR]: state => ({ ...state, isLoading: true }),
    [DELETE_OUTPUT_INDICATOR_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_OUTPUT_INDICATOR_FAILURE]: state => ({ ...state, isLoading: false }),
    [DELETE_PARTNER_DISTRICT]: state => ({
      ...state,
      isLoading: true
    }),
    [DELETE_PARTNER_DISTRICT_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false
      }
    },
    [DELETE_PARTNER_DISTRICT_FAILURE]: state => ({ ...state, isLoading: false }),
    [DELETE_PURCHASE_REQUEST]: state => ({ ...state, isLoading: true }),
    [DELETE_PURCHASE_REQUEST_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_PURCHASE_REQUEST_FAILURE]: state => ({ ...state, isLoading: false }),
    [DELETE_SUPPLIER]: state => ({ ...state, isLoading: true }),
    [DELETE_SUPPLIER_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_SUPPLIER_FAILURE]: state => ({ ...state, isLoading: false }),
    [DELETE_WORKSITE]: state => ({ ...state, isLoading: true }),
    [DELETE_WORKSITE_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_WORKSITE_FAILURE]: state => ({ ...state, isLoading: false }),
    [DELETE_WORKSPACE]: state => ({ ...state, isLoading: true }),
    [DELETE_WORKSPACE_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_WORKSPACE_FAILURE]: state => ({ ...state, isLoading: false }),
    [FETCH_AGREEMENT_TEMPLATE]: startLoading,
    [FETCH_AGREEMENT_TEMPLATE_FAILURE]: loadingError,
    [FETCH_AGREEMENT_TEMPLATE_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleAgreementTemplateData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_AGREEMENT_TEMPLATES]: startLoading,
    [FETCH_AGREEMENT_TEMPLATES_FAILURE]: loadingError,
    [FETCH_AGREEMENT_TEMPLATES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        errorMessage: null,
        hasSubmitted: false,
        isError: false,
        isLoading: false,
        isSuccess: true
      }

      newState.paginatedAgreementTemplatesData.agreementTemplatesPageCount =
        payload.agreementTemplatesPageCount || payload.pageCount || 0
      newState.paginatedAgreementTemplatesData.agreementTemplatesCount =
        payload.agreementTemplatesCount || 0
      newState.paginatedAgreementTemplatesData.agreementTemplates = payload.agreementTemplates || []

      return newState
    },
    [FETCH_BUDGET_CATEGORY]: startLoading,
    [FETCH_BUDGET_CATEGORY_FAILURE]: loadingError,
    [FETCH_BUDGET_CATEGORY_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleBudgetCategoryData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_BUDGET_CATEGORIES]: startLoading,
    [FETCH_BUDGET_CATEGORIES_FAILURE]: loadingError,
    [FETCH_BUDGET_CATEGORIES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        errorMessage: null,
        hasSubmitted: false,
        isError: false,
        isLoading: false,
        isSuccess: true
      }

      newState.paginatedBudgetCategoriesData.budgetCategoriesPageCount =
        payload.budgetCategoriesPageCount || payload.pageCount || 0
      newState.paginatedBudgetCategoriesData.budgetCategoriesCount =
        payload.budgetCategoriesCount || 0
      newState.paginatedBudgetCategoriesData.budgetCategories = payload.budgetCategories || []

      return newState
    },
    [FETCH_DISTRICTS]: startLoading,
    [FETCH_DISTRICTS_FAILURE]: loadingError,
    [FETCH_DISTRICTS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        errorMessage: null,
        hasSubmitted: false,
        isError: false,
        isLoading: false,
        isSuccess: true,
        provincesData: payload.provinces
      }

      newState.paginatedDistrictsData.districtsPageCount =
        payload.districtsPageCount || payload.pageCount || 0
      newState.paginatedDistrictsData.districtsCount = payload.districtsCount || 0
      newState.paginatedDistrictsData.districts = payload.districts || []

      return newState
    },
    [FETCH_EMPLOYEE_DETAILS]: startLoading,
    [FETCH_EMPLOYEE_DETAILS_FAILURE]: loadingError,
    [FETCH_EMPLOYEE_DETAILS_SUCCESS]: (state, { payload }) => ({
      ...state,
      errorMessage: null,
      hasSubmitted: false,
      isError: false,
      isLoading: false,
      isSuccess: true,
      employeeDetails: payload
    }),
    [UPDATE_BUDGET_CATEGORY]: startSubmitting,
    [UPDATE_BUDGET_CATEGORY_FAILURE]: loadingError,
    [UPDATE_BUDGET_CATEGORY_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_PARTNER_DISTRICT]: startSubmitting,
    [UPDATE_PARTNER_DISTRICT_FAILURE]: loadingError,
    [UPDATE_PARTNER_DISTRICT_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_PAYROLL_REQUEST]: startSubmitting,
    [UPDATE_PAYROLL_REQUEST_FAILURE]: loadingError,
    [UPDATE_PAYROLL_REQUEST_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [FETCH_OUTPUT]: startLoading,
    [FETCH_OUTPUT_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleOutputData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_OUTPUT_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true
    }),
    [FETCH_OUTPUT_AREA]: startLoading,
    [FETCH_OUTPUT_AREA_FAILURE]: loadingError,
    [FETCH_OUTPUT_AREA_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleOutputAreaData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_OUTPUT_INDICATOR]: startLoading,
    [FETCH_OUTPUT_INDICATOR_FAILURE]: loadingError,
    [FETCH_OUTPUT_INDICATOR_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleOutputIndicatorData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_MUNICIPALITY]: startLoading,
    [FETCH_MUNICIPALITY_FAILURE]: loadingError,
    [FETCH_MUNICIPALITY_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleMunicipalityData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_MUNICIPALITIES]: startLoading,
    [FETCH_MUNICIPALITIES_FAILURE]: loadingError,
    [FETCH_MUNICIPALITIES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      // newState.paginatedMunicipalitiesData.municipalitiesPageCount =
      //   payload.municipalitiesPageCount || payload.pageCount || 0
      // newState.paginatedMunicipalitiesData.municipalitiesCount = payload.municipalitiesCount || 0
      // newState.paginatedMunicipalitiesData.municipalities = payload.municipalities || []

      newState.paginatedMunicipalitiesData.municipalitiesPageCount = 1
      newState.paginatedMunicipalitiesData.municipalitiesCount = payload.length
      newState.paginatedMunicipalitiesData.municipalities = payload || []

      return newState
    },
    [FETCH_ONBOARDING_QUESTIONS]: startLoading,
    [FETCH_ONBOARDING_QUESTIONS_FAILURE]: loadingError,
    [FETCH_ONBOARDING_QUESTIONS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null,
        onboardingQuestionsData: payload
      }

      return newState
    },
    [FETCH_OWN_PROFILE_DETAILS]: startLoading,
    [FETCH_OWN_PROFILE_DETAILS_FAILURE]: loadingError,
    [FETCH_OWN_PROFILE_DETAILS_SUCCESS]: (state, { payload }) => ({
      ...state,
      ownProfileDetails: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_PARTNER_DISTRICT]: startLoading,
    [FETCH_PARTNER_DISTRICT_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true,
      singlePartnerDistrictError: true
    }),
    [FETCH_PARTNER_DISTRICT_SUCCESS]: (state, { payload }) => ({
      ...state,
      provincesData: payload.provinces,
      singlePartnerDistrictData: payload.district,
      isLoading: false,
      isError: false
    }),
    [FETCH_PARTNER_DISTRICTS]: startLoading,
    [FETCH_PARTNER_DISTRICTS_FAILURE]: loadingError,
    [FETCH_PARTNER_DISTRICTS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedPartnerDistrictsData.partnerDistrictsPageCount =
        payload.partnerDistrictsPageCount || payload.pageCount || 0
      newState.paginatedPartnerDistrictsData.partnerDistrictsCount =
        payload.partnerDistrictsCount || 0
      newState.paginatedPartnerDistrictsData.partnerDistricts = payload.partnerDistricts || []

      return newState
    },
    [FETCH_OUTPUT_AREAS]: startLoading,
    [FETCH_OUTPUT_AREAS_FAILURE]: loadingError,
    [FETCH_OUTPUT_AREAS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedOutputAreasData.outputAreasPageCount =
        payload.outputAreasPageCount || payload.pageCount || 0
      newState.paginatedOutputAreasData.outputAreasCount = payload.outputAreasCount || 0
      newState.paginatedOutputAreasData.outputAreas = payload.outputAreas || []
      newState.paginatedOutputAreasData.outputSubAreas = payload.outputSubAreas || []

      return newState
    },
    [FETCH_OUTPUTS]: startLoading,
    [FETCH_OUTPUTS_FAILURE]: loadingError,
    [FETCH_OUTPUTS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedOutputsData.outputsPageCount =
        payload.outputsPageCount || payload.pageCount || 0
      newState.paginatedOutputsData.outputsCount = payload.outputsCount || 0
      newState.paginatedOutputsData.outputs = payload.outputs || []

      return newState
    },
    [FETCH_OUTPUT_INDICATORS]: startLoading,
    [FETCH_OUTPUT_INDICATORS_FAILURE]: loadingError,
    [FETCH_OUTPUT_INDICATORS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedOutputIndicatorsData.outputIndicatorsPageCount =
        payload.outputIndicatorsPageCount || payload.pageCount || 0
      newState.paginatedOutputIndicatorsData.outputIndicatorsCount =
        payload.outputIndicatorsCount || 0
      newState.paginatedOutputIndicatorsData.outputIndicators = payload.outputIndicators || []

      return newState
    },
    [FETCH_PARTNERS]: startLoading,
    [FETCH_PARTNERS_FAILURE]: loadingError,
    [FETCH_PARTNERS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedPartnersData.partnersPageCount =
        payload.partnersPageCount || payload.pageCount || 0
      newState.paginatedPartnersData.partnersCount = payload.partnersCount || 0
      newState.paginatedPartnersData.partners = payload.partners || []

      return newState
    },
    [FETCH_PAYROLL_HISTORY]: startLoading,
    [FETCH_PAYROLL_HISTORY_FAILURE]: loadingError,
    [FETCH_PAYROLL_HISTORY_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedPayrollData.payrollPageCount =
        payload.payrollPageCount || payload.pageCount || 0
      newState.paginatedPayrollData.payrollCount = payload.payrollCount || 0
      newState.paginatedPayrollData.payroll = payload.payroll || []

      return newState
    },
    [FETCH_PAYROLL_PAYMENT_REQUEST]: startLoading,
    [FETCH_PAYROLL_PAYMENT_REQUEST_FAILURE]: loadingError,
    [FETCH_PAYROLL_PAYMENT_REQUEST_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null,
        singlePaymentRequestData: payload
      }

      return newState
    },
    [FETCH_PLACE]: startLoading,
    [FETCH_PLACE_FAILURE]: loadingError,
    [FETCH_PLACE_SUCCESS]: (state, { payload }) => ({
      ...state,
      singlePlaceData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_PLACES]: startLoading,
    [FETCH_PLACES_FAILURE]: loadingError,
    [FETCH_PLACES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedPlacesData.placesPageCount =
        payload.placesPageCount || payload.pageCount || 0
      newState.paginatedPlacesData.placesCount = payload.placesCount || 0
      newState.paginatedPlacesData.places = payload.places || []

      return newState
    },
    [FETCH_PURCHASE_REQUEST]: startLoading,
    [FETCH_PURCHASE_REQUEST_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true,
      singlePurchaseRequestData: { ...state.singlePurchaseRequestData, isError: true }
    }),
    [FETCH_PURCHASE_REQUEST_SUCCESS]: (state, { payload }) => ({
      ...state,
      singlePurchaseRequestData: payload,
      isLoading: false,
      isError: false,
      isSuccess: true
    }),
    [FETCH_PURCHASE_REQUESTS]: startLoading,
    [FETCH_PURCHASE_REQUESTS_FAILURE]: loadingError,
    [FETCH_PURCHASE_REQUESTS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        isSuccess: true,
        hasSubmitted: false,

        errorMessage: null
      }

      newState.paginatedPurchaseRequestsData.purchaseRequestsPageCount =
        payload.purchaseRequestsPageCount || payload.pageCount || 0
      newState.paginatedPurchaseRequestsData.purchaseRequestsCount =
        payload.purchaseRequestsCount || 0
      newState.paginatedPurchaseRequestsData.purchaseRequests = payload.purchaseRequests || []

      return newState
    },
    [FETCH_STATS]: startLoading,
    [FETCH_STATS_FAILURE]: loadingError,
    [FETCH_STATS_SUCCESS]: (state, { payload }) => ({
      ...state,
      statsData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_SUPPLIER]: startLoading,
    [FETCH_SUPPLIER_FAILURE]: loadingError,
    [FETCH_SUPPLIER_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleSupplierData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_SUPPLIERS]: startLoading,
    [FETCH_SUPPLIERS_FAILURE]: loadingError,
    [FETCH_SUPPLIERS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedSuppliersData.suppliersPageCount =
        payload.suppliersPageCount || payload.pageCount || 0
      newState.paginatedSuppliersData.suppliersCount = payload.suppliersCount || 0
      newState.paginatedSuppliersData.suppliers = payload.suppliers || []

      return newState
    },
    [FETCH_TIMEKEEPERS]: startLoading,
    [FETCH_TIMEKEEPERS_FAILURE]: loadingError,
    [FETCH_TIMEKEEPERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isError: false,
      isLoading: false,
      hasSubmitted: false,
      isSuccess: true,
      errorMessage: null,
      timekeepersData: payload
    }),
    [FETCH_WARD]: startLoading,
    [FETCH_WARD_FAILURE]: loadingError,
    [FETCH_WARD_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleWardData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_WARDS]: startLoading,
    [FETCH_WARDS_FAILURE]: loadingError,
    [FETCH_WARDS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      // newState.paginatedWardsData.wardsPageCount = payload.wardsPageCount || payload.pageCount || 0
      // newState.paginatedWardsData.wardsCount = payload.wardsCount || 0
      // newState.paginatedWardsData.wards = payload.wards || []

      newState.paginatedWardsData.wardsPageCount = 1
      newState.paginatedWardsData.wardsCount = payload.length
      newState.paginatedWardsData.wards = payload || []

      return newState
    },
    [FETCH_WORKSITE]: startLoading,
    [FETCH_WORKSITE_FAILURE]: loadingError,
    [FETCH_WORKSITE_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleWorksiteData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_WORKSITES]: startLoading,
    [FETCH_WORKSITES_FAILURE]: loadingError,
    [FETCH_WORKSITES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedWorksitesData.worksitesPageCount =
        payload.worksitesPageCount || payload.pageCount || 0
      newState.paginatedWorksitesData.worksitesCount = payload.worksitesCount || 0
      newState.paginatedWorksitesData.worksites = payload.worksites || []

      return newState
    },
    [FETCH_WORKSPACE]: startLoading,
    [FETCH_WORKSPACE_FAILURE]: loadingError,
    [FETCH_WORKSPACE_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleWorkspaceData: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_WORKSPACES]: startLoading,
    [FETCH_WORKSPACES_FAILURE]: loadingError,
    [FETCH_WORKSPACES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null
      }

      newState.paginatedWorkspacesData.workspacesPageCount =
        payload.workspacesPageCount || payload.pageCount || 0
      newState.paginatedWorkspacesData.workspacesCount = payload.workspacesCount || 0
      newState.paginatedWorkspacesData.workspaces = payload.workspaces || []

      return newState
    },
    [LOGOUT_SUCCESS]: () => initialState,
    [IMPORT_PARTICIPANTS]: startSubmitting,
    [IMPORT_PARTICIPANTS_FAILURE]: loadingError,
    [IMPORT_PARTICIPANTS_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [INITIATE_PAYROLL_REQUEST]: startSubmitting,
    [INITIATE_PAYROLL_REQUEST_FAILURE]: loadingError,
    [INITIATE_PAYROLL_REQUEST_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        payrollObject: payload.data,
        isSuccess: true
      }
    },
    [ONBOARD_EMPLOYEE]: startSubmitting,
    [ONBOARD_EMPLOYEE_FAILURE]: loadingError,
    [ONBOARD_EMPLOYEE_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [REQUEST_AGREEMENT_ZIP]: startSubmitting,
    [REQUEST_AGREEMENT_ZIP_FAILURE]: loadingError,
    [REQUEST_AGREEMENT_ZIP_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        requestedZipFile: payload.data,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [SEND_OUTCOMES_TRACKING_REMINDER]: startSubmitting,
    [SEND_OUTCOMES_TRACKING_REMINDER_FAILURE]: loadingError,
    [SEND_OUTCOMES_TRACKING_REMINDER_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [SUBMIT_INDICATOR_OUTCOMES]: startSubmitting,
    [SUBMIT_INDICATOR_OUTCOMES_FAILURE]: loadingError,
    [SUBMIT_INDICATOR_OUTCOMES_SUCCESS]: (state, { payload }) => {
      const stateOutputs = state.paginatedOutputsData.outputs || []
      const newState = {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }

      const latestOutputs = stateOutputs.filter(o => {
        const isSameOutput =
          o.output_indicator_id === parseInt(payload.outcome_indicator_id, 10) &&
          o.outcome_encoded_date === payload.outcome_encoded_date
        return !isSameOutput
      })

      latestOutputs.push({
        number_type: payload.number_type,
        output_indicator_id: parseInt(payload.outcome_indicator_id, 10),
        outcome_encoded_date: payload.outcome_encoded_date,
        achieved_amount: payload.achieved_amount,
        output_target: payload.output_target,
        outcome_description: payload.outcome_description
      })

      newState.paginatedOutputsData.outputs = latestOutputs

      return newState
    },
    [UPDATE_AGREEMENT_TEMPLATE]: startSubmitting,
    [UPDATE_AGREEMENT_TEMPLATE_FAILURE]: loadingError,
    [UPDATE_AGREEMENT_TEMPLATE_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_MUNICIPALITY]: (state, { payload }) => {
      const set = state.municipalitiesData || []
      const payloadId = payload.id
      const recordUpdated = false
      const recordUpdating = true
      const updatedData = { set, payload, payloadId, recordUpdated, recordUpdating }

      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        isSubmitting: true,
        municipalitiesData: updatedData
      }
    },
    [UPDATE_MUNICIPALITY_FAILURE]: (state, payload) => ({
      ...state,
      errorMessage: payload ? payload.errorMessage : null,
      isLoading: false,
      isSubmitting: false,
      isSuccess: false
    }),
    [UPDATE_MUNICIPALITY_SUCCESS]: (state, { payload }) => {
      const updatedData = state.municipalitiesData.map(r => {
        if (!idsMatch(payload.id, r.id)) return r
        return { ...r, status: payload.status }
      })

      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true,
        municipalitiesData: updatedData
      }
    },
    [UPDATE_OUTPUT]: startSubmitting,
    [UPDATE_OUTPUT_FAILURE]: loadingError,
    [UPDATE_OUTPUT_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_OUTPUT_AREA]: startSubmitting,
    [UPDATE_OUTPUT_AREA_FAILURE]: loadingError,
    [UPDATE_OUTPUT_AREA_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_OUTPUT_INDICATOR]: startSubmitting,
    [UPDATE_OUTPUT_INDICATOR_FAILURE]: loadingError,
    [UPDATE_OUTPUT_INDICATOR_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_PLACE]: (state, { payload }) => {
      const set = state.placesData || []
      const payloadId = payload.id
      const recordUpdated = false
      const recordUpdating = true
      const updatedData = { set, payload, payloadId, recordUpdated, recordUpdating }

      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        isSubmitting: true,
        placesData: updatedData
      }
    },
    [UPDATE_PLACE_FAILURE]: (state, payload) => ({
      ...state,
      errorMessage: payload ? payload.errorMessage : null,
      isLoading: false,
      isSubmitting: false,
      isSuccess: false
    }),
    [UPDATE_PLACE_SUCCESS]: (state, { payload }) => {
      const updatedData = state.placesData.map(r => {
        if (!idsMatch(payload.id, r.id)) return r
        return { ...r, status: payload.status }
      })

      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true,
        placesData: updatedData
      }
    },
    [UPDATE_PURCHASE_REQUEST]: startSubmitting,
    [UPDATE_PURCHASE_REQUEST_FAILURE]: loadingError,
    [UPDATE_PURCHASE_REQUEST_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_SUPPLIER]: startSubmitting,
    [UPDATE_SUPPLIER_FAILURE]: loadingError,
    [UPDATE_SUPPLIER_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_WARD]: (state, { payload }) => {
      const set = state.wardsData || []
      const payloadId = payload.id
      const recordUpdated = false
      const recordUpdating = true
      const updatedData = { set, payload, payloadId, recordUpdated, recordUpdating }

      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        isSubmitting: true,
        wardsData: updatedData
      }
    },
    [UPDATE_WARD_FAILURE]: loadingError,
    [UPDATE_WARD_SUCCESS]: (state, { payload }) => {
      const updatedData = state.wardsData.map(r => {
        if (!idsMatch(payload.id, r.id)) return r
        return { ...r, status: payload.status }
      })

      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true,
        wardsData: updatedData
      }
    },
    [UPDATE_WORKSITE]: startSubmitting,
    [UPDATE_WORKSITE_FAILURE]: loadingError,
    [UPDATE_WORKSITE_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_WORKSPACE]: startSubmitting,
    [UPDATE_WORKSPACE_FAILURE]: loadingError,
    [UPDATE_WORKSPACE_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    }
  },
  initialState
)
